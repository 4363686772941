import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Banner_btn from "../components/Common/Banner_btn";
import HomeSectionsHeading from "../components/Common/HomeSectionsHeading";
import HomePageBtn from "../components/Common/HomePageBtn";
import DataTable_comp from "../components/Common/DataTable_comp";
import {
  VerticalPackingTableData1,
  VerticalPackingTableData2,
  VerticalPackingTableData3,
  VerticalPackingTableData4,
  VerticalPackingTableData5,
  VerticalPackingTableData6,
  VerticalPackingTableData7,
} from "../utils/Data";
import { Element, scroller } from "react-scroll";
import Divider_comp from "../components/Common/Divider_comp";
import Divider_comp_2 from "../components/Common/Divider_comp_2";

function VerticalPacking() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = window.location.hash.substring(1);
    if (hash) {
      scroller.scrollTo(hash);
    }
  }, []);


  const packing1Card = [
    require("../assets/packing/card/vertical-packing/vp-1.png"),
    require("../assets/packing/card/vertical-packing/vp-2.png"),
    require("../assets/packing/card/vertical-packing/vp-3.png"),
    require("../assets/packing/card/vertical-packing/vp-4.png"),
  ];

  const packing2Card = [
    require("../assets/packing/card/vertical-packing/vp-5.png"),
    require("../assets/packing/card/vertical-packing/vp-6.png"),
    require("../assets/packing/card/vertical-packing/vp-7.png"),
    require("../assets/packing/card/vertical-packing/vp-8.png"),
  ];

  const packing3Card = [
    require("../assets/packing/card/vertical-packing/vp-9.png"),
    require("../assets/packing/card/vertical-packing/vp-10.png"),
    require("../assets/packing/card/vertical-packing/vp-11.png"),
    require("../assets/packing/card/vertical-packing/vp-12.png"),
  ];

  const packing4Card = [
    require("../assets/packing/card/vertical-packing/vp-13.png"),
    require("../assets/packing/card/vertical-packing/vp-14.png"),
    require("../assets/packing/card/vertical-packing/vp-15.png"),
    require("../assets/packing/card/vertical-packing/vp-16.png"),
  ];

  const packing5Card = [
    require("../assets/packing/card/vertical-packing/vp-17.png"),
    require("../assets/packing/card/vertical-packing/vp-18.png"),
    require("../assets/packing/card/vertical-packing/vp-19.png"),
    require("../assets/packing/card/vertical-packing/vp-20.png"),
  ];

  const packing6Card = [
    require("../assets/packing/card/vertical-packing/vp-21.png"),
    require("../assets/packing/card/vertical-packing/vp-22.png"),
    require("../assets/packing/card/vertical-packing/vp-23.png"),
    require("../assets/packing/card/vertical-packing/vp-24.png"),
  ];

  const packing7Card = [
    require("../assets/packing/card/vertical-packing/vp-25.png"),
    require("../assets/packing/card/vertical-packing/vp-26.png"),
    require("../assets/packing/card/vertical-packing/vp-27.png"),
    require("../assets/packing/card/vertical-packing/vp-28.png"),
  ];


  return (
    <>
      <div className="container-fluid p-0 m-0 packing-banner">
        <div className="container">
          <Row className="h-100 w-100">
            <Col className="contact-banner-content">
              <div className="contact-banner-inner">
                <h1>VERTICAL PACKING</h1>
                <div className="d-flex pt-1">
                  <div className="contact-line" />
                  <a>
                    Versatile, High-Speed Packing Solutions for Reliable,
                    <br />
                    Contamination-Free Operation
                  </a>
                </div>
              </div>
            </Col>
            <Col className="contact-banner-content-left">
              <div className="contact-banner-inner-left">
                <Banner_btn
                  right={
                    <>
                      VERTICAL
                      <br />
                      PACKING
                    </>
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <section>
        <Element name="pre-made-bag">
          <div className="container common-spacing2">
            <div className="row">
              <div className="col col-12 col-lg-6">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={"Preformed Pouch Filling Sealing Machine"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description mt-3">
                    This system is designed for quantitative packaging of
                    various materials such as candy, seeds, potato chips, nuts,
                    biscuits, raisins, chocolates, cereals, and pet foods. It
                    features an automatic detection function to prevent feeding
                    and heat sealing when the bag is not properly opened,
                    allowing reuse and minimizing material waste. An alarm will
                    sound if the working air pressure is abnormal or the heating
                    pipe is faulty. The system includes a plexiglass safety
                    cover to protect the operator. It uses prefabricated bags to
                    reduce material loss, it can be adapted to package liquids,
                    sauces, granules, powders, and other materials by selecting
                    different gauges.
                  </p>
                  <h4 className="packing-category-heading text-uppercase">
                   Packing Samples
                  </h4>
                  <div className=" d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start">
                    {packing1Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col col-12 col-lg-6 d-flex flex-column justify-content-between  ">
                <div className="text-center text-lg-end mt-4 mt-md-5 mt-lg-0">
                  <Image
                    src={require("../assets/packing/file (4).png")}
                    className="machines-image machines-image-padding-right"
                  />
                </div>
              </div>
            </div>
            <div className="row packing-inner-container-left">
              <div className="col ">
                <h4 className="packing-category-heading  text-uppercase">
                  Main technical parameters
                </h4>
                <div className="table-container">
                  <DataTable_comp tableData={VerticalPackingTableData1} />
                </div>
              </div>
            </div>
          </div>
        </Element>

        <div className="container">
          <Divider_comp />
        </div>

        <Element name="multi-head-weighing-machine">
          <div className="container common-spacing2">
            <div className="row">
              <div className="order-2 order-lg-1 col col-12 col-lg-6 d-flex justify-content-center justify-content-lg-none align-items-center">
                <div className="text-center text-lg-end mt-md-5 mt-lg-0">
                  <Image
                    src={require("../assets/packing/file (2).png")}
                    className="machines-image machines-image-padding-left"
                  />
                </div>
              </div>
              <div className="order-1 order-lg-2 col col-12 col-lg-6">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={"Multi head Weighing and Packing Machine"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description mt-3">
                    This machine is ideal for packing high-precision and easily
                    breakable materials such as puffed food, potato chips,
                    french fries, snacks, candy, and pet food. It is constructed
                    entirely of stainless steel, except for specific materials,
                    and all structures adhere to food hygiene standards.
                    Equipped with an imported PLC computer control system, the
                    touch screen is straightforward and user-friendly. The servo
                    film transporting system ensures precise positioning, while
                    the fully automatic warning protection function helps
                    minimize losses. Additionally, the machine can create both
                    pillow-type bags and standing bags, customizable to meet
                    customer requirements.
                  </p>
                  <h4 className="packing-category-heading text-uppercase">
                 Packing Samples
                  </h4>
                  <div className=" d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start">
                    {packing2Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="row">
                <div className="col-0 col-lg-6"></div>
                <div className="col-12 col-lg-6 ">
                  <h4 className="packing-category-heading text-uppercase packing-inner-container-left">
                    Main technical parameters
                  </h4>
                </div>
              </div>
              <div className="table-container packing-inner-container-left">
                <DataTable_comp tableData={VerticalPackingTableData2} />
              </div>
            </div>
          </div>
        </Element>

        <div className="container">
          <Divider_comp_2 />
        </div>

        <Element name="powder-packing-machine">
          <div className="container common-spacing2">
            <div className="row">
              <div className="col col-12 col-lg-6">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={"Imported Powder packing machine"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description mt-3">
                    Primarily suitable for industries such as food, daily
                    necessities, chemicals, pharmaceuticals, and hardware, this
                    system ensures the efficient transport of square, round
                    bars, and other products throughout the entire production
                    line, minimizing manual handling and preventing secondary
                    contamination of food. It can seamlessly integrate with the
                    front-end production line, with a precise design that avoids
                    material deformation due to compression. In cases where
                    material becomes jammed, stacked, or fails to turn properly,
                    it can be automatically cleared without stopping, ensuring
                    continuous and stable production and packaging. The system
                    uses multi-stage frequency conversion speed regulation and
                    servo drive control, offering simple, convenient operation,
                    stable performance, high control accuracy, and low energy
                    consumption. Constructed with food-grade stainless steel and
                    aluminum alloy, the entire line achieves full automation in
                    material transportation, finishing, arrangement, and
                    packaging, thereby eliminating manual handling and
                    preventing secondary food contamination.
                  </p>
                  <h4 className="packing-category-heading text-uppercase">
                   Packing Samples
                  </h4>
                  <div className=" d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start">
                    {packing3Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-end">
                <div className="text-center text-lg-end mt-4 mt-md-5 mt-lg-0">
                  <Image
                    src={require("../assets/packing/file (17).png")}
                    className="machines-image"
                  />
                </div>
              </div>
            </div>
            <div className="row packing-inner-container-left">
              <div className="col">
                <h4 className="packing-category-heading text-uppercase">
                  Main technical parameters
                </h4>
                <div className="table-container">
                  <DataTable_comp tableData={VerticalPackingTableData3} />
                </div>
              </div>
            </div>
          </div>
        </Element>

        <div className="container">
          <Divider_comp />
        </div>

        <Element name="powder-sachet-machine">
          <div className="container common-spacing2">
            <div className="row">
              <div className="order-2 order-lg-1 col col-12 col-lg-6 d-flex justify-content-center justify-content-lg-none align-items-center">
                <div className="text-center text-lg-end mt-4 mt-md-5 mt-lg-0">
                  <Image
                    src={require("../assets/packing/file (19).png")}
                    className="machines-image machines-image-padding-left"
                  />
                </div>
              </div>

              <div className="order-1 order-lg-2 col col-12 col-lg-6">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={"Imported Powder Sachet Packing Machine"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description mt-3">
                    This machine is suitable for packaging various non-sticky
                    powders such as food, daily chemicals, condiments, and
                    medicines (e.g., milk powder, soy milk powder, walnut
                    powder, spice powder, talcum powder). With microcomputer
                    control, it can automatically complete bag making, feeding,
                    counting, sealing, and coding. The cursor is automatically
                    tracked, and faults are diagnosed and displayed on the
                    screen. It offers high precision, high efficiency, and no
                    damage.
                  </p>
                  <h4 className="packing-category-heading text-uppercase">
                   Packing Samples
                  </h4>
                  <div className=" d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start">
                    {packing7Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="row">
                <div className="col-0 col-lg-6"></div>
                <div className="col-12 col-lg-6">
                  <h4 className="packing-category-heading packing-inner-container-left text-uppercase">
                    Main technical parameters
                  </h4>
                </div>
              </div>
              <div className="table-container packing-inner-container-left">
                <DataTable_comp tableData={VerticalPackingTableData4} />
              </div>
            </div>
          </div>
        </Element>

        <div className="container">
          <Divider_comp_2 />
        </div>

        <Element name="automatic-feeding-machine">
          <div className="container common-spacing2">
            <div className="row">
              <div className="col col-12 col-lg-6">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={"Granule Sachet Packing Machine"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description mt-3">
                    This machine is suitable for packaging puffed food, peanuts,
                    popcorn, oatmeal, sunflower seeds, sugar, salt, washing
                    powder, and more. It completes packaging and printing of the
                    date simultaneously, saving time and costs. Its optimized
                    structural design allows for easy disassembly, assembly, and
                    cleaning. The horizontal and vertical sealing temperatures
                    are independently controlled to better adapt to different
                    packaging materials.{" "}
                  </p>
                  <h4 className="packing-category-heading text-uppercase">
                   Packing Samples
                  </h4>
                  <div className=" d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start">
                    {packing4Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col col-12 col-lg-6 d-flex flex-column justify-content-between align-itemm-center">
                <div className="text-center  mt-4 mt-md-5 mt-lg-0">
                  <Image
                    src={require("../assets/packing/file (8).png")}
                    className="machines-image"
                  />
                </div>
              </div>
            </div>
            <div className="row packing-inner-container-left">
              <div className="col">
                <h4 className="packing-category-heading text-uppercase">
                  Main technical parameters
                </h4>
                <div className="table-container">
                  <DataTable_comp tableData={VerticalPackingTableData5} />
                </div>
              </div>
            </div>
          </div>
        </Element>

        <div className="container">
          <Divider_comp />
        </div>

        <Element name="juice-filling-machine">
          <div className="container common-spacing2">
            <div className="row">
              <div className="order-2 order-lg-1 col col-12 col-lg-6 d-flex flex-column justify-content-center  justify-content-lg-between">
                <div className="text-center  mt-md-5 mt-lg-0">
                  <Image
                    src={require("../assets/packing/file (3).png")}
                    className="machines-image"
                  />
                </div>
              </div>

              <div className="order-1 order-lg-2 col col-12 col-lg-6">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={"MultiLane Sachet Packing Machine"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description mt-3">
                    This machine is capable of packaging powders, liquids,
                    pastes, and granules such as sugar and coffee in long,
                    narrow pouches. Different materials can be packed using
                    different dosing units. The machine can automatically
                    complete multi-lane product measuring, feeding, filling, bag
                    forming, date code printing, bag sealing, and cutting, with
                    hole punching available as an option. It features a servo
                    motor-driven heat seal film pulling system and a
                    high-sensitivity fiber optic photo sensor for accurate color
                    mark tracing. The PLC control system combined with a touch
                    screen allows easy setting and changing of packaging
                    parameters, and it displays daily production output and
                    self-diagnostic machine errors directly on the screen.
                    Additionally, the PID temperature controller monitors the
                    heat sealing temperature within ±1℃.{" "}
                  </p>
                  <h4 className="packing-category-heading text-uppercase">
                   Packing Samples
                  </h4>
                  <div className=" d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start">
                    {packing5Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="row">
                <div className="col-0  col-lg-6"></div>
                <div className="col-12 col-lg-6">
                  <h4 className="packing-category-heading packing-inner-container-left text-uppercase">
                    Main technical parameters
                  </h4>
                </div>
              </div>
              <div className="table-container packing-inner-container-left">
                <DataTable_comp tableData={VerticalPackingTableData6} />
              </div>
            </div>
          </div>
        </Element>

        <div className="container">
          <Divider_comp_2 />
        </div>

        <Element name="abcd">
          <div className="container common-spacing2">
            <div className="row">
              <div className="col col-12 col-lg-6">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={"Liquid & Paste Sachet Packing Machine"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description mt-3">
                    This machine is suitable for the quantitative packaging of
                    fluids such as edible oil, juice, shampoo, ketchup, chili
                    sauce, and more. It features an advanced single-chip control
                    system that ensures precise cursor positioning, simple
                    operation, low noise, stable performance, and strong
                    practicality. The machine automatically completes bag
                    making, sealing, filling, cutting, and cursor tracking.{" "}
                  </p>
                  <h4 className="packing-category-heading text-uppercase">
                   Packing Samples
                  </h4>
                  <div className=" d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start">
                    {packing6Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col col-12 col-lg-6 d-flex flex-column justify-content-center justify-content-lg-between">
                <div className="text-center text-lg-end mt-4 mt-md-5 mt-lg-0">
                  <Image
                    src={require("../assets/packing/file (1).png")}
                    className="machines-image machines-image-padding-right"
                  />
                </div>
              </div>
            </div>

            <div className="row packing-inner-container-left">
              <div className="col">
                <h4 className="packing-category-heading text-uppercase">
                  Main technical parameters
                </h4>
                <div className="table-container">
                  <DataTable_comp tableData={VerticalPackingTableData7} />
                </div>
              </div>
            </div>
          </div>
        </Element>

        <div className="container common-margin-bottom">
          <Divider_comp />
        </div>
      </section>
    </>
  );
}

export default VerticalPacking;
