import React from "react";
import { FaArrowRight } from "react-icons/fa";



const data = [
  {
    src: require("../../assets/images/Email-Icon.png"),
    subTitle: "Send Email",
    title: "Email Address",
    info: "info@revolutionarymachines.com",
  },
  {
    src: require("../../assets/images/phoneIcon.png"),
    subTitle: "Call Now",
    title: "Contact Number",
    // info: "+44 1234567890",
  },
  {
    src: require("../../assets/images/Location-Icon.png"),
    subTitle: "Find US Here",
    title: "Our Location",
    // info: "Bolton Street Bolton, United Kingdom BL3 CHJ",
  },
];

const ContactComponent = () => {

  return (
    <div className="container row">
      {data.map((data, index) => (
        <div className="col-12 col-6 col-xl-4 text-primary mb-5 mb-lg-0" key={index}>
          <div className=" container text-center">
            <div className="contact-icon-box">
              <img className="contact-icon " src={data.src} />
            </div>
            <div className="mt-2 mt-md-4">
              <span className="lato-bold text-uppercase contact-sub-title align-items-center">
                {data.subTitle} <FaArrowRight style={{marginLeft:10 , marginBottom:2}}/>
              </span>
              <h2 className="lato-black text-uppercase pt-md-3 contact-title">
                {data.title}
              </h2>
              <div className="pt-md-3">
                <span className="lato-regular contact-info">{data.info}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactComponent;
