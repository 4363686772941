import React, { useState, useEffect, useRef } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import Banner_btn from "../components/Common/Banner_btn";
import {
  FaBullseye,
  FaEye,
  FaFacebookSquare,
  FaQuestionCircle,
  FaWhatsappSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import { RiCheckDoubleLine } from "react-icons/ri";
import HomeSectionsHeading from "../components/Common/HomeSectionsHeading";

const words = ["Create", "Build", "Develop"];

function AboutUs() {
  const [index, setIndex] = useState(0);
  const textRef = useRef(null);
  const backgroundRef = useRef(null);

  useEffect(() => {
    const updateBackgroundWidth = () => {
      if (textRef.current && backgroundRef.current) {
        const textWidth = textRef.current.offsetWidth;
        backgroundRef.current.style.width = `${textWidth}px`;
      }
    };

    updateBackgroundWidth(); // Set initial width

    const startAnimation = () => {
      if (textRef.current) {
        textRef.current.classList.add('text-enter');
      }
    };

    const intervalId = setInterval(() => {
      // Reset animation class
      if (textRef.current) {
        textRef.current.classList.remove('text-enter');
      }

      setIndex((prevIndex) => (prevIndex + 1) % words.length);

      // Update background width and trigger text animation
      setTimeout(() => {
        updateBackgroundWidth(); // Adjust width after text update

        if (textRef.current) {
          textRef.current.classList.add('text-enter');
        }
      }, 50); // Delay to let the background adjust first
    }, 3000); // Change word every 3 seconds

    // Initial animation delay for the first load
    const initialAnimationDelay = setTimeout(startAnimation, 50);

    return () => {
      clearInterval(intervalId);
      clearTimeout(initialAnimationDelay);
    };
  }, []);
  return (
    <div>
      <div className="container-fluid p-0 m-0 about-us">
        <div className="container">
          <Row className="h-100 w-100">
            <Col className="contact-banner-content">
              <div className="contact-banner-inner">
                <h1>ABOUT US</h1>
                <div className="d-flex pt-1">
                  <div className="contact-line" />
                  <a>
                    Leading professional provider of food packaging <br />
                    equipment in the United Kingdom
                  </a>
                </div>
              </div>
            </Col>
            <Col className="contact-banner-content-left">
              <div className="contact-banner-inner-left">
                <Banner_btn right="ABOUT US" />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <section className="about-section">
        <div className="container about-contianer">
          <h2 className="section-title letter-spacing word-spacing d-block d-lg-flex align-items-center  ">
            WE ARE REVOLUTIONARY MACHINES, WE{" "}
            <div className="text-container py-2 mx-1">
              <span className="background" ref={backgroundRef}></span>
              <p className="text section-title letter-spacing word-spacing text-uppercase" ref={textRef}>
                {words[index]}
              </p>
            </div>
            SOLUTIONS!
          </h2>

          {/* <span className="highlight">BUILD</span> */}

          <p className="section-description">
            Revolutionary Machines is a leading professional provider of food
            packaging equipment in the United Kingdom, and is committed to
            provide competitive total full-process solutions.
          </p>
          <div className="row  gy-4 gy-lg-0">
            <div className="col col-12 col-lg-4">
              <div className="card about-card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-center pb-4">
                    <div className="circle-container">
                      <div className="circle" />
                      <img
                        src={require("../assets/images/vission.png")}
                        alt="Vision Icon"
                        className="about-img"
                      />
                    </div>
                  </div>
                  <h1 className="card-title">OUR VISION!</h1>
                  <p className="card-text">
                    To be the most trustworthy brand in the food processing
                    industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card about-card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-center pb-4">
                    <div className="circle-container">
                      <div className="circle" />
                      <img
                        src={require("../assets/images/mission.png")}
                        alt="Mission Icon"
                        className="about-img"
                      />
                    </div>
                  </div>
                  <h1 className="card-title">OUR MISSION!</h1>
                  <p className="card-text">
                    To strive for manufacturing simpler and safer food
                    processing machinery.
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-12 col-lg-4">
              <div className="card about-card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-center pb-4">
                    <div className="circle-container">
                      <div className="circle" />
                      <img
                        src={require("../assets/images/why_us.png")}
                        alt="Why Us Icon"
                        className="about-img"
                      />
                    </div>
                  </div>
                  <h1 className="card-title">WHY US?</h1>
                  <p className="card-text">
                    Revolutionary Machines is a leading professional provider of
                    food packaging.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="horizontal-line" />
      <section className="experience">
        <div className="container experience-container">
          <div className="row ">
            <div className="order-2 order-lg-1 col-12 col-lg-6 p-0 m-0  d-flex justify-content-center align-items-center">
              <Image
                src={require("../assets/images/experience_banner.png")}
                alt="Excavator"
                className="img-fluid experience-image"
              />
            </div>
            <div className="order-1 order-lg-2 col-12 col-lg-6 experience-content common-spacing2">
              {/* <h3>WE HAVE 10+ YEARS OF EXPERIENCE</h3>
              <h1>WANT TO KNOW MORE?</h1> */}
              <HomeSectionsHeading
                padding="2.1px"
                title={"WE HAVE 10+ YEARS OF EXPERIENCE"}
                heading={`WANT TO KNOW MORE?`}
              />
              <p>
                Revolutionary Machines is a leading provider of food packaging
                equipment in the UK, offering competitive full-process
                solutions, safe and reliable products, and efficient
                professional services to the global food industry. Our dedicated
                engineer team listens to customer needs, providing practical and
                personalized solutions while focusing on reducing investment
                costs and maximizing value returns. We prioritize design and
                production by investing in advanced equipment such as automatic
                fiber laser cutting machines, CNC machine tools, and welding
                robots. Our comprehensive quality management system, certified
                ISO9001 in 2015, ensures high-quality products.
              </p>
              <p>
                We also offer exceptional pre-sales and after-sales services
                through our professional customer service and aftersales teams.
                Our goal is to provide worry-free service throughout the entire
                process. As food safety is fundamental, we are committed to
                enhancing our technology, products, and services to become the
                most trustworthy brand in the global food processing industry.
              </p>
              <div className="features row ">
                <div className="col-12 col-md-6 col-lg-5 d-flex align-items-center features-container  mt-2">
                  <div className="feature d-flex align-items-center bg-light justify-content-center rounded">
                    <Image
                      className=""
                      src={require("../assets/images/featureIcon.png")}
                    />
                  </div>
                  <span className="lato-black">
                    TRUSTED
                    <br />
                    COMPANY
                  </span>
                </div>
                <div className="col-12 col-md-6 col-lg-7 d-flex align-items-center  features-container  mt-2">
                  <div className="feature d-flex align-items-center bg-light justify-content-center rounded">
                    <Image
                      className=""
                      src={require("../assets/images/featureIcon.png")}
                    />
                  </div>
                  <span className="lato-black">
                    DEDICATED TEAM</span>
                </div>
                <div className="col-12 col-md-6 col-lg-5 d-flex align-items-center features-container mt-2 mt-lg-4 mt-md-4">
                  <div className="feature d-flex align-items-center bg-light justify-content-center rounded">
                    <Image
                      className=""
                      src={require("../assets/images/featureIcon.png")}
                    />
                  </div>
                  <span className="lato-black">
                    100%
                    <br />
                    SATISFACTION
                  </span>
                </div>
                <div className="col-12 col-md-6 col-lg-7 d-flex align-items-center features-container mt-2 mt-lg-4 mt-md-4">
                  <div className="feature d-flex align-items-center bg-light justify-content-center rounded">
                    <Image
                      className=""
                      src={require("../assets/images/featureIcon.png")}
                    />
                  </div>
                  <span className="lato-black">
                    CONTINUOUS
                    <br />
                    IMPROVEMENT
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="container about-contact ">
          <Form>
            <div className="row">
              <div className="col-md-6 ">
                <h1 className="get_in">
                  Get in <span className="get_in touch">touch</span>
                </h1>
                <p>
                  Need Assistance? Please contact us, and we will respond within
                  24 hours.
                </p>
                <Form.Group className="mb-4">
                  <Form.Control
                    className="input-style"
                    placeholder="Name / Company *"
                    type="text"
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Control
                    className="input-style"
                    placeholder="Email *"
                    type="text"
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Control
                    className="input-style"
                    placeholder="Phone Number *"
                    type="text"
                  />
                </Form.Group>
                <button className="button1">SEND</button>
              </div>
              <div className="col-md-6 d-flex align-items-end">
                <div className="w-100">
                  <Form.Group className="">
                    <Form.Control
                      className="text_area_input-style"
                      placeholder="Your message"
                      as="textarea"
                      rows={8}
                    />
                  </Form.Group>

                  <div className="d-flex align-items-center social-links pt-3">
                    <a href="https://web.whatsapp.com/" target="_blank">
                      <FaWhatsappSquare
                        size={50}
                        className="whatsapp"
                        style={{ marginRight: 10 }}
                      />
                    </a>
                    <a href="https://www.instagram.com/" target="_blank">
                      <Image
                        src={require("./../assets/images/instagram.png")}
                        className="mx-2"
                        style={{ height: 50, width: 50 }}
                      />
                    </a>
                    <a href="https://www.youtube.com/" target="_blank">
                      <FaYoutubeSquare size={50} className="mx-2 youtube" />
                    </a>
                    <a href="https://www.facebook.com/" target="_blank">
                      <FaFacebookSquare size={50} className="mx-2 facebook" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </section> */}
    </div>
  );
}

export default AboutUs;
