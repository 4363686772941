import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Banner_btn from "../components/Common/Banner_btn";
import HomeSectionsHeading from "../components/Common/HomeSectionsHeading";
import PowderPackingComp from "../components/Common/PowderPackingComp";
import Divider_comp from "../components/Common/Divider_comp";

const PowderProcessing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <div className="container-fluid p-0 m-0 packing-banner">
        <div className="container">
          <Row className="h-100 w-100">
            <Col className="contact-banner-content col-12 col-lg-7">
              <div className="contact-banner-inner">
                <h1>FALVORING POWDER PROCESSING</h1>
                <div className="d-flex pt-1">
                  <div className="contact-line" />
                  <a>
                    Versatile, High-Speed Packing Solutions for Reliable,
                    <br />
                    Contamination-Free Operation
                  </a>
                </div>
              </div>
            </Col>
            <Col className="contact-banner-content-left">
              <div className="contact-banner-inner-left">
                <Banner_btn
                  right={
                    <>
                      POWDER
                      <br />
                      PROCESSING
                    </>
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>


      <div className="container common-spacing2">
        <div className="row align-items-center">
          <div className="col col-md-12">
            <HomeSectionsHeading
              title={"Efficient Automated Packing"}
              heading={"Stretch Film Vacuum Packing Machine"}
            />
            <div className="packing-inner-container-left">
              <p className="packing-description mt-3">
                This machine is ideal for vacuum packaging various products,
                including frozen and divided meat, meat products, soy
                products, snack foods, fruits and vegetables, pickles,
                chilled meat, medical products, hardware components, and
                medical equipment. The machine's frame is made from
                high-strength alloy aluminum, formed in one piece for high
                mechanical strength, a stable structure, no deformation, and
                excellent precision. It offers high pumping efficiency, high
                vacuum, low noise, stable and reliable quality, and a long
                service life. The PLC control system and servo motor provide
                high running precision and ensure the automatic coordination
                and stability of each action for reliable operation.
                Additionally, the mold can be designed according to user
                requirements, with simple and fast mold replacement.{" "}
              </p>
              <h4 className="packing-category-heading text-uppercase">
               Packing Samples
              </h4>
            </div>
          </div>
        </div>
      </div>

      
      <div className="container common-spacing-side">
        <div className="packing-inner-container-left">
          <PowderPackingComp />
        </div>
      </div>
      <div className="container common-margin-bottom common-margin-top">
        <Divider_comp />
      </div>

    </>
  );
};

export default PowderProcessing;
