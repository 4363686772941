import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Banner_btn from "../components/Common/Banner_btn";
import HomeSectionsHeading from "../components/Common/HomeSectionsHeading";
import HomePageBtn from "../components/Common/HomePageBtn";
import DataTable_comp from "../components/Common/DataTable_comp";
import {
  OverWrappingTableData1,
  OverWrappingTableData2,
  OverWrappingTableData3,
} from "../utils/Data";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Divider_comp_2 from "../components/Common/Divider_comp_2";
import Divider_comp from "../components/Common/Divider_comp";

const packing1Card = [
  require("../assets/packing/card/overwrapping/OW-1.png"),
  require("../assets/packing/card/overwrapping/OW-2.png"),
  require("../assets/packing/card/overwrapping/OW-3.png"),
  require("../assets/packing/card/overwrapping/OW-4.png"),
];

const packing2Card = [
  require("../assets/packing/card/overwrapping/OW-5.png"),
  require("../assets/packing/card/overwrapping/OW-6.png"),
  require("../assets/packing/card/overwrapping/OW-7.png"),
  require("../assets/packing/card/overwrapping/OW-8.png"),
];

const packing3Card = [
  require("../assets/packing/card/overwrapping/OW-9.png"),
  require("../assets/packing/card/overwrapping/OW-10.png"),
  require("../assets/packing/card/overwrapping/OW-11.png"),
  require("../assets/packing/card/overwrapping/OW-12.png"),
];
const OverWrapping = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = window.location.hash.substring(1);
    if (hash) {
      scroller.scrollTo(hash);
    }
  }, []);
  return (
    <>
      <div className="container-fluid p-0 m-0 packing-banner">
        <div className="container">
          <Row className="h-100 w-100">
            <Col className="contact-banner-content">
              <div className="contact-banner-inner">
                <h1>OVERWRAPPING</h1>
                <div className="d-flex pt-1">
                  <div className="contact-line" />
                  <a>
                    Versatile, High-Speed Packing Solutions for Reliable,
                    <br />
                    Contamination-Free Operation
                  </a>
                </div>
              </div>
            </Col>
            <Col className="contact-banner-content-left">
              <div className="contact-banner-inner-left">
                <Banner_btn
                  right={
                    <>
                      OVER
                      <br />
                      WRAPPING
                    </>
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Element name="cellophane-machine">
        <div className="container common-spacing2">
          <div className="row align-items-center">
            <div className="col col-12 col-lg-6">
              <HomeSectionsHeading
                title={"Efficient Automated Packing"}
                heading={"Automated Cellophane Wrapping Machine"}
              />
              <div className="packing-inner-container-left">
                <p className="packing-description mt-3">
                  This series of wrapping machines are widely used for wrapping
                  single boxes or groups of multiple-package boxes in industries
                  such as medicines, health products, food, cosmetics,
                  stationery, and audio-visual products (with tear tape). They
                  feature multifunctional digital display, frequency conversion
                  stepless speed regulation, and PLC programming control
                  technology, allowing for automatic box feeding, counting, and
                  touch display for easy man-machine interface. The machines are
                  designed with a reasonable structure, elegant appearance,
                  stable operation, and convenient adjustment, and they can be
                  integrated with other production lines.{" "}
                </p>
                <h4 className="packing-category-heading text-uppercase">
                 Packing Samples
                </h4>
                <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start packaging-card-container">
                  {packing1Card.map((v, i) => {
                    return (
                      <div key={i}>
                        <Image src={v} className="card-image" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col col-12 col-lg-6 d-flex flex-column justify-content-between">
              <div className="text-center text-lg-end mt-4 mt-md-5 mt-lg-0">
                <Image
                  src={require("../assets/packing/file (24).png")}
                  className="machines-image"
                />
              </div>
            </div>
          </div>
          <div className="row packing-inner-container-left">
            <div className="col">
              <h4 className="packing-category-heading text-uppercase">
                Main technical parameters
              </h4>
              <div className="table-container">
                <DataTable_comp tableData={OverWrappingTableData1} />
              </div>
            </div>
          </div>
        </div>
      </Element>

      <div className="container">
        <Divider_comp />
      </div>

      <Element name="lbar-machine">
        <div className="container common-spacing2">
          <div className="row align-items-center">
            <div className="order-2 order-md-1 col col-12 col-lg-6 d-flex flex-column justify-content-between">
              <div className="text-center text-lg-end mt-4 mt-md-5 mt-lg-0">
                <Image
                  src={require("../assets/packing/file (7).png")}
                  className="machines-image"
                />
              </div>
            </div>
            <div className="order-1 order-md-2 col col-12 col-lg-6">
              <HomeSectionsHeading
                title={"Efficient Automated Packing"}
                heading={"L Bar Shrink and WrapMachine"}
              />
              <div className="packing-inner-container-left">
                <p className="packing-description mt-3">
                  This machine automatically completes feeding, bagging,
                  sealing, and shrinking, making it ideal for industries such as
                  printing, boxes, greeting cards, photo albums, medicines, and
                  cosmetics. The sealing knife is made of special materials with
                  a high-temperature resistant, anti-adhesive coating to ensure
                  strong and crack-free sealing lines. It uses both horizontal
                  and vertical photoelectric sensors, allowing for easy
                  switching and efficient packaging of thin or small products.
                  The machine features automatic feeding, with length adjustment
                  controlled by a combination of photoelectric and timer
                  settings. It is equipped with an induction motor for automatic
                  waste coiling. Additionally, when changing the size of the
                  package, there is no need to change the mold, making the
                  adjustment process very simple.{" "}
                </p>
                <h4 className="packing-category-heading text-uppercase">
                 Packing Samples
                </h4>
                <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start packaging-card-container">
                  {packing2Card.map((v, i) => {
                    return (
                      <div key={i}>
                        <Image src={v} className="card-image" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-0 col-lg-6"></div>
              <div className="col-12 col-lg-6">
                <h4 className="packing-category-heading packing-inner-container-left text-uppercase">
                  Main technical parameters
                </h4>
              </div>
            </div>
            <div className="table-container">
              <DataTable_comp tableData={OverWrappingTableData2} />
            </div>
          </div>
        </div>
      </Element>

      <div className="container">
        <Divider_comp_2 />
      </div>

      <Element name="sleeve-shrink-machine">
        <div className="container common-spacing2">
          <div className="row align-items-center">
            <div className="col col-12 col-lg-6">
              <HomeSectionsHeading
                title={"Efficient Automated Packing"}
                heading={"Sleeve Shrink Wrapping Machine"}
              />
              <div className="packing-inner-container-left">
                <p className="packing-description mt-3">
                  This machine is suitable for wrapping pop cans, bottles, and
                  similar items, with or without a bottom tray. It features
                  upper and lower electromagnetic switches to prevent sealing
                  mistakes and protect the operator. The machine can be easily
                  adjusted to match different packing sizes. It offers simple
                  operation and maintenance with high packing efficiency.
                  Additionally, an automatic alarm security system protects both
                  the machine and the operator.{" "}
                </p>
                <h4 className="packing-category-heading text-uppercase">
                 Packing Samples
                </h4>
                <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start packaging-card-container">
                  {packing3Card.map((v, i) => {
                    return (
                      <div key={i}>
                        <Image src={v} className="card-image" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col col-12 col-lg-6 d-flex flex-column justify-content-between">
              <div className="text-center text-lg-end mt-4 mt-md-5 mt-lg-0">
                <Image
                  src={require("../assets/packing/file (26).png")}
                  className="machines-image"
                />
              </div>
            </div>
          </div>
          <div className="row packing-inner-container-left">
            <div className="col">
              <h4 className="packing-category-heading text-uppercase">
                Main technical parameters
              </h4>
              <div className="table-container">
                <DataTable_comp tableData={OverWrappingTableData3} />
              </div>
            </div>
          </div>
        </div>
      </Element>

      <div className="container common-margin-bottom">
        <Divider_comp />
      </div>
    </>
  );
};

export default OverWrapping;
