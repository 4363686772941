import React from 'react';
import { useMediaQuery } from 'react-responsive';

// import 'bootstrap/dist/css/bootstrap.min.css';

function DataTable_comp({ tableData }) {

    const isSmallScreen = useMediaQuery({ query: '(max-width: 360px)' });
    const isMediumScreen = useMediaQuery({ query: '(max-width: 768px)' });


    const tdStyles = {

        fontSize: isSmallScreen? '10px' : isMediumScreen? '12px' : '15px',
        wordWrap: 'break-word',
        whiteSpace: 'normal',

    }


    const thStyles = {
        fontSize: isSmallScreen? '12px' : isMediumScreen? '14px' : '16px',
    }
    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover table-striped" style={{}}>
                <thead className="table-heading">
                    <tr>
                        {tableData.headings.map((heading, index) => {
                            const isObject = typeof heading === 'object' && heading !== null;
                            return (
                                <th style={thStyles}
                                    key={index}
                                    className="text-center text-wrap"
                                    colSpan={isObject ? heading.colspan || 1 : 1}
                                    rowSpan={isObject ? heading.rowspan || 1 : 1}
                                >
                                    {isObject ? heading.value : heading}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {tableData.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((data, cellIndex) => {
                                const isObject = typeof data === 'object' && data !== null;
                                return (
                                    <td
                                        key={cellIndex}
                                        className="text-center text-wrap lato-regular"
                                        style={tdStyles}
                                        colSpan={isObject ? data.colspan || 1 : 1}
                                        rowSpan={isObject ? data.rowspan || 1 : 1}
                                    >
                                        {isObject ? data.value : data}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default DataTable_comp;
