import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Banner_btn from "../components/Common/Banner_btn";
import HomeSectionsHeading from "../components/Common/HomeSectionsHeading";
import HomePageBtn from "../components/Common/HomePageBtn";
import DataTable_comp from "../components/Common/DataTable_comp";
import { VacuumTableData1 } from "../utils/Data";
import { Element, animateScroll as scroll, scroller } from "react-scroll";
import Divider_comp_2 from "../components/Common/Divider_comp_2";
import Divider_comp from "../components/Common/Divider_comp";

function VacuumPacking() {
  const packing1Card = [
    require("../assets/packing/card/VacuumCard/Rectangle 63-1.png"),
    require("../assets/packing/card/VacuumCard/Rectangle 63.png"),
    require("../assets/packing/card/VacuumCard/Rectangle 65-1.png"),
    require("../assets/packing/card/VacuumCard/Rectangle 65.png"),
    require("../assets/packing/card/VacuumCard/Rectangle 67.png"),
    require("../assets/packing/card/VacuumCard/Rectangle 68-1.png"),
    require("../assets/packing/card/VacuumCard/Rectangle 68.png"),
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = window.location.hash.substring(1);
    if (hash) {
      scroller.scrollTo(hash);
    }
  }, []);
  return (
    <>
      <div className="container-fluid p-0 m-0 packing-banner">
        <div className="container">
          <Row className="h-100 w-100">
            <Col className="contact-banner-content">
              <div className="contact-banner-inner">
                <h1>VACUUM PACKING</h1>
                <div className="d-flex pt-1">
                  <div className="contact-line" />
                  <a>
                    Versatile, High-Speed Packing Solutions for Reliable,
                    <br />
                    Contamination-Free Operation
                  </a>
                </div>
              </div>
            </Col>
            <Col className="contact-banner-content-left">
              <div className="contact-banner-inner-left">
                <Banner_btn
                  right={
                    <>
                      VACUUM
                      <br />
                      PACKING
                    </>
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <section>
        <Element name="stretch-film-machine">
          <div className="container common-spacing2">
            <div className="row align-items-center">
              <div className="col col-md-12">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={"Stretch Film Vacuum Packing Machine"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description mt-3">
                    This machine is ideal for vacuum packaging various products,
                    including frozen and divided meat, meat products, soy
                    products, snack foods, fruits and vegetables, pickles,
                    chilled meat, medical products, hardware components, and
                    medical equipment. The machine's frame is made from
                    high-strength alloy aluminum, formed in one piece for high
                    mechanical strength, a stable structure, no deformation, and
                    excellent precision. It offers high pumping efficiency, high
                    vacuum, low noise, stable and reliable quality, and a long
                    service life. The PLC control system and servo motor provide
                    high running precision and ensure the automatic coordination
                    and stability of each action for reliable operation.
                    Additionally, the mold can be designed according to user
                    requirements, with simple and fast mold replacement.{" "}
                  </p>
                  <h4 className="packing-category-heading text-uppercase">
                   Packing Samples
                  </h4>
                  <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start packaging-card-container">
                    {packing1Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Element>

        <div className="container  d-flex justify-content-center">
          <Image
            src={require("../assets/Vacuum/file (27).png")}
            className="machines-image"
          />
        </div>
        <div className="container common-spacing2 d-flex justify-content-center">
          <div className="row packing-inner-container-left">
            <div className="col">
              <h4 className="packing-category-heading  text-uppercase">
                Main technical parameters
              </h4>
              <div className="table-container">
                <DataTable_comp tableData={VacuumTableData1} />
              </div>
            </div>
          </div>
        </div>

        <div className="container common-margin-bottom">
          <Divider_comp />
        </div>
      </section>
    </>
  );
}

export default VacuumPacking;
