import React, { useState } from "react";

const data = [
  {
    id: 1,
    title: `fresh<br/>garlic`,
    image: require("../../assets/Powder-Packing/Rectangle 131.png"),
    childImages: [
      {
        id: 1,
        src: require("../../assets/Powder-Packing/Rectangle 132.png"),
        description: "Garlic <br/> Powder",
      },
      {
        id: 2,
        src: require("../../assets/Powder-Packing/Rectangle 133.png"),
        description: "Garlic <br/> granules",
      },
      {
        id: 3,
        src: require("../../assets/Powder-Packing/Rectangle 134.png"),
        description: "Garlic <br/> flakes",
      },
    ],
  },
  {
    id: 2,
    title: "fresh<br/> ginger",
    image: require("../../assets/Powder-Packing/Rectangle 135.png"),
    childImages: [
      {
        id: 4,
        src: require("../../assets/Powder-Packing/Rectangle 136.png"),
        description: "ginger <br/> flakes",
      },
      {
        id: 5,
        src: require("../../assets/Powder-Packing/Rectangle 139.png"),
        description: "ginger <br/> granules",
      },
      {
        id: 6,
        src: require("../../assets/Powder-Packing/Rectangle 138.png"),
        description: "ginger <br/> powder",
      },
    ],
  },
  {
    id: 3,
    title: "fresh<br/> onion",
    image: require("../../assets/Powder-Packing/Rectangle 140.png"),
    childImages: [
      {
        id: 7,
        src: require("../../assets/Powder-Packing/Rectangle 141.png"),
        description: "onion <br/> flakes",
      },
      {
        id: 8,
        src: require("../../assets/Powder-Packing/Rectangle 142.png"),
        description: "onion <br/> granules",
      },
      {
        id: 9,
        src: require("../../assets/Powder-Packing/Rectangle 143.png"),
        description: "onion <br/> powder",
      },
    ],
  },
  {
    id: 4,
    title: "dehydrated fruits<br/> and vegetables",
    image: require("../../assets/Powder-Packing/Rectangle 144.png"),
    childImages: [
      {
        id: 10,
        src: require("../../assets/Powder-Packing/Rectangle 145.png"),
        description: "cabbage <br/> powder",
      },
      {
        id: 11,
        src: require("../../assets/Powder-Packing/Rectangle 146.png"),
        description: "carrot <br/> flakes",
      },
      {
        id: 12,
        src: require("../../assets/Powder-Packing/Rectangle 147.png"),
        description: "egg <br/> granules",
      },
    ],
  },
  {
    id: 5,
    title: "natural<br/> spices",
    image: require("../../assets/Powder-Packing/Rectangle 148.png"),
    childImages: [
      {
        id: 13,
        src: require("../../assets/Powder-Packing/Rectangle 149.png"),
        description: "chilli <br/> powder",
      },
      {
        id: 14,
        src: require("../../assets/Powder-Packing/Rectangle 150.png"),
        description: "turmeric <br/> powder",
      },
      {
        id: 15,
        src: require("../../assets/Powder-Packing/Rectangle 151.png"),
        description: "pepper",
      },
    ],
  },
];


const PowderPackingComp = () => {
  const [isVisible, setIsVisible] = useState(null);
  const [isVisibleChildren, setIsVisibleChildren] = useState(null);

  return (
    <div>
      {data.map((item, index) => (
        <div
          className={`d-flex flex-row justify-content-between ${index === 0
            ? "powder-packing-container-first"
            : "powder-packing-container"
            }`}
          key={index}
        >
          <div
            className="position-relative"
            onMouseEnter={() => setIsVisible(item.id)}
            onMouseLeave={() => setIsVisible(null)}
          >
            <div
              className={`d-flex justify-content-center align-items-center powder-image-overlay ${item.id === isVisible ? "powder-image-overlay-visible" : ""}`}
            >
              <h2 className="heading-content lato-bold text-uppercase p-0 m-0 text-white text-center"
                dangerouslySetInnerHTML={{ __html: item.title }} />
            </div>
            <img
              src={item.image}
              className="powder-images"
              alt={`Main for ${item.title}`}
            />
          </div>

          <div className="d-flex gap-2 flex-column justify-content-between flex-grow-1">
            {item.childImages.map((childImage, i) => (
              <div
                className="d-flex justify-content-between align-items-center"
                key={i}
              >
                <div className="d-flex flex-grow-1 align-items-center justify-content-center d-lg-flex">
                  <img
                    src={require("../../assets/Powder-Packing/Vector 32.png")}
                    alt="Vector"
                    className="arrow-vector"
                  />
                </div>
                <div
                  className="position-relative"
                  onMouseEnter={() => setIsVisibleChildren(childImage.id)}
                  onMouseLeave={() => setIsVisibleChildren(null)}
                >
                  <div
                    className={`d-flex justify-content-center align-items-center powder-image-overlay-children ${childImage.id === isVisibleChildren ? "powder-image-overlay-visible-children" : ""}`}
                  >
                    <h5
                      className="heading-content lato-bold text-uppercase p-0 m-0 text-white text-center"
                      dangerouslySetInnerHTML={{ __html: childImage.description }}
                    />
                  </div>
                  <img
                    src={childImage.src}
                    className="powder-images-child"
                    alt={`Child for ${childImage.description}`}
                  />
                </div>
              </div>
            ))}
          </div>

        </div>
      ))}
    </div>
  );
};

export default PowderPackingComp;
