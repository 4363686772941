import React, { useEffect, useState } from 'react';
import { Col, Form, Image, Row } from 'react-bootstrap';
import Banner_btn from '../components/Common/Banner_btn';
import { FaFacebookSquare, FaInstagramSquare, FaWhatsappSquare, FaYoutubeSquare } from 'react-icons/fa';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    message: ''
  });
  const [isLoading, setIsLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({});
  const [formSuccess, setFormSuccess] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validateForm = () => {
    let errors = {};

    // Name validation
    if (!formData.name) {
      errors.name = 'Name is required.';
    }

    // Email validation
    if (!formData.email) {
      errors.email = 'Email is required.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      errors.email = 'Invalid email address.';
    }

    // Phone number validation
    if (!formData.number) {
      errors.number = 'Phone number is required.';
    } else if (!/^\+?\d{10,15}$/.test(formData.number)) {
      errors.number = 'Invalid phone number. Must be 10-15 digits long.';
    }

    // Message validation
    if (!formData.message) {
      errors.message = 'Message is required.';
    }

    return errors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    } else {
      setFormErrors({});
    }
    setIsLoading(true)
    try {
      const response = await fetch('https://backend.revolutionarymachines.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          subject: 'Contact Us Form Submission',
          ...formData
        })
      });

      const result = await response.json();
      if (result.success) {
        setFormSuccess('Email sent successfully!');
        setFormErrors({});
        setFormData({
          name: '',
          email: '',
          number: '',
          message: ''
        });
        setIsLoading(false);
        // Clear the success message after 10 seconds
        setTimeout(() => {
          setFormSuccess('');
        }, 10000);
      } else {
        setFormSuccess('');
        setFormErrors({ submit: `Failed to send email: ${result.message}` });
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
      setFormSuccess('');
      setFormErrors({ submit: `Failed to send email: ${error.message}` });
      setIsLoading(false)
    }
  };

  return (
    <div>
      <div className='container-fluid p-0 m-0 contact-us'>
        <div className='container'>
          <Row className='h-100 w-100'>
            <Col className='contact-banner-content'>
              <div className='contact-banner-inner'>
                <h1>CONTACT US</h1>
                <div className='d-flex pt-1'>
                  <div className='contact-line' />
                  <a>Leading professional provider of food packaging <br />
                    equipment in the United Kingdom</a>
                </div>
              </div>
            </Col>
            <Col className='contact-banner-content-left'>
              <div className='contact-banner-inner-left'>
                <Banner_btn />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <section style={{ display: 'flex' }}>
        <div className='contact-form'>
          <div className='contact-form-inner'>
            <h1 className='get_in'>Get in <span className='get_in touch'>touch</span></h1>
            <p>Need Assistance? Please contact us, and we will respond within 24 hours.</p>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4">
                <Form.Control
                  className='input-style'
                  placeholder='Name / Company *'
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {formErrors.name && <p style={{ color: 'red' }}>{formErrors.name}</p>}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Control
                  className='input-style'
                  placeholder='Email *'
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {formErrors.email && <p style={{ color: 'red' }}>{formErrors.email}</p>}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Control
                  className='input-style'
                  placeholder='Phone Number *'
                  type="text"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                />
                {formErrors.number && <p style={{ color: 'red' }}>{formErrors.number}</p>}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Control
                  className='text_area_input-style'
                  placeholder='Your message'
                  as="textarea"
                  rows={5}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
                {formErrors.message && <p style={{ color: 'red' }}>{formErrors.message}</p>}
              </Form.Group>
              {isLoading && <p style={{ color: 'warning' }}>Email is Sending...</p>}
              {formErrors.submit && <p style={{ color: 'red' }}>{formErrors.submit}</p>}
              {formSuccess && <p style={{ color: 'green' }}>{formSuccess}</p>}
              <button disabled={isLoading ? true : false} type="submit" className='button1 mb-4'>SEND</button>

              <div className='d-flex align-items-center social-links'>
                <a href="https://web.whatsapp.com/" target="_blank">
                  <FaWhatsappSquare size={50} className='whatsapp' style={{ marginRight: 10 }} />
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  <Image src={require('./../assets/images/instagram.png')} className='mx-2' style={{ height: 50, width: 50 }} />
                </a>
                <a href="https://www.youtube.com/" target="_blank">
                  <FaYoutubeSquare size={50} className='mx-2 youtube' />
                </a>
                <a href="https://www.facebook.com/" target="_blank">
                  <FaFacebookSquare size={50} className='mx-2 facebook' />
                </a>
              </div>
            </Form>
          </div>
        </div>
        <div className='contact-form-left' />
      </section>

      <section className='section-color'>
        <div className="container contact-info-box">
          <div className="row d-flex justify-content-center align-items-center" >
            <div className="col-12 col-lg-4 ">
              <div className="info-box align-content-center justify-content-center">
                <div>
                  <h3>SEND EMAIL</h3>
                </div>
                <h1>Email Address</h1>
                <p>info@revolutionarymachines.com</p>
                <img src={require('../assets/images/EmailIcon.png')} alt="Email Icon" />
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="info-box align-content-center justify-content-center">
                <div>
                  <h3>CALL NOW</h3>
                </div>
                <h1>Contact Number</h1>
                <p></p>
                <img src={require('../assets/images/phoneIcon.png')} alt="Phone Icon" />
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="info-box align-content-center justify-content-center">
                <div>
                  <h3>FIND US HERE</h3>
                </div>
                <h1>Our Location</h1>
                <p></p>
                <img src={require('../assets/images/LocationIcon.png')} alt="Location Icon" />
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default ContactUs;
