import React from 'react'
import HomePageBtn from './HomePageBtn'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';

const Divider_comp_2 = ({ isLine = false }) => {

  const isSmallScreen = useMediaQuery({ query: '(max-width: 360px)' });
  const isMediumScreen = useMediaQuery({ query: '(max-width: 768px)' });
//   const isLargeScreen = useMediaQuery({ query: '(max-width: 1200px)' });



  const buttonStyles = {
    // fontSize: isSmallScreen ? '0.8rem' : isMediumScreen ? '0.9rem' : '1rem',
    marginRight: isSmallScreen ? '0px' : isMediumScreen ? '10px' : '40px',
    marginLeft: isSmallScreen ? '10px' : isMediumScreen ? '0px' : '0px',

  };
  const navigate = useNavigate()
  return (
    <div>
      <div className=' d-flex align-items-center justify-content-start justify-content-md-between divider-comp'  >
        <div className='custom-horizontal-line_2' />
        {!isLine &&
          <HomePageBtn btnText={"Contact us For Consultation"}  style={buttonStyles}  onClick={() => navigate('/ContactUs')}/>
        }
      </div>
    </div>
  )
}

export default Divider_comp_2
