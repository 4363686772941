import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import HomePageBtn from "../components/Common/HomePageBtn";
import HomeSectionsHeading from "../components/Common/HomeSectionsHeading";
import { FaArrowRight, FaFacebookSquare, FaWhatsappSquare, FaYoutubeSquare } from "react-icons/fa";
import Slider from "../components/Common/Slider";
import AccordionComp from "../components/Common/Accordion_comp";
import Divider_comp from "../components/Common/Divider_comp";
import ContactComponent from "../components/Common/ContactComponent";
import { categories } from "../utils/Data";
import Divider_comp_2 from "../components/Common/Divider_comp_2";
import { useNavigate } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import { useMediaQuery } from 'react-responsive';





const accordantData = [
  {
    title: "Do you sell other Machinery not on your website?",
    content:
      "All of the machines we manufacture ourselves are available on our online shop.  However, we distribute a wide range of food packaging machines in addition to those we make ourselves.  Please contact us for more information at info@revolutionarymachines.com",
  },
  {
    title: "How do I track my order?",
    content:
      "Once your order has been despatched you will receive notification via email of the courier and tracking number. Often there is a link to direct you to their website to track the progress of your order.",
  },
  {
    title: "How long will my delivery take?",
    content:
      "We aim to despatch all orders as soon as possible, Once your order has been despatched you will receive an email from our carrier advising the expected date of delivery of your parcel.",
  },
  {
    title: "Do you provide International delivery?",
    content:
      "Currently we only sell to companies based in the UK through our website, however we do export to other countries all over the world.  Please send us an email to discuss this further at info@revolutionarymachines.com",
  },
  {
    title: "Can I easily buy spare parts for my Machines?",
    content:
      "Spare parts for your machines will be available soon. We are currently finalizing the logistics to ensure a smooth and efficient supply process. Please stay tuned for updates, and we appreciate your patience as we work to meet your needs.",
  },
];

function Home() {

  
  const isSmallScreen = useMediaQuery({ query: '(max-width: 360px)' });
  const isMediumScreen = useMediaQuery({ query: '(max-width: 768px)' });
//   const isLargeScreen = useMediaQuery({ query: '(max-width: 1200px)' });



  const selectedCategoryStyles = {
    fontSize: isSmallScreen ? '12px' : isMediumScreen ? '14px' : '18px',
    

  };



  const categoryKeys = Object.keys(categories);
  const [selectedCategory, setSelectedCategory] = useState(
    categories[categoryKeys[0]]
  );
  const handleCategoryClick = (categoryKey) => {
    setSelectedCategory(categories[categoryKey]);
  };
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = window.location.hash.substring(1);
    if (hash) {
      scroller.scrollTo(hash);
    }
  }, []);


  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 360);



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 360);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubscribe = async () => {
    setError('');
    setSuccess('');

    if (!email) {
      setError('Email is required.');
      return;
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    // return console.log("alsdjalsdjflajsdlfe", email)
    setLoading(true);

    try {
      const response = await fetch('https://backend.revolutionarymachines.com/newsletter-subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subject: 'Newsletter Subscription',
          email,
        }),
      });

      const result = await response.json();

      if (result.success) {
        setSuccess('You have successfully subscribed to our newsletter!');
        setEmail('');
        setTimeout(() => {
          setSuccess('')
        }, 10000);
      } else {
        setError(`Failed to subscribe: ${result.message}`);
      }
    } catch (error) {
      setError(`Failed to subscribe: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div>
      {/* Hero Section  */}
      <div className="hero_section d-flex align-items-center">
        <video autoPlay loop muted className="video-background" playsInline>
          <source src={require('../assets/Home/Video/Home-Banner.mp4')} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <div className="gradient-overlay"></div> */}
        {/* <div className="container common-spacing hero_section_inner text-start">
          <div>
            <h1>
              Food packaging
              <br />
              Machineries
            </h1>
            <h2 className="pt-3">Reliable, Advanced and Efficient</h2>
          </div>
          <div className="d-flex pt-3">
            <button className="hero_btn hero_btn_1" onClick={() => scroller.scrollTo('all_category')}>
              <span>All categories</span>
            </button>
            <button className="hero_btn hero_btn_2" onClick={() => navigate('/ContactUs')}>
              <span>Contact Us</span>
            </button>
          </div>
        </div> */}
      </div>

      {/* Hero Section  */}

      {/* Company Intro Section  */}

      
      <div className="d-flex justify-content-center align-items-center">
        <div className="main-banner">
          <div className="container  common-spacing">
            <Row>
              <Col sm={12} md={12} lg={6}>
                <HomeSectionsHeading
                  title={"About our company"}
                  heading={`We are a company<br/>that Believes In Quality!`}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description">
                    Revolutionary Machines is a leading provider of food packaging
                    equipment in the UK, offering competitive full-process
                    solutions, safe and reliable products, and efficient
                    professional services to the global food industry. Our dedicated
                    engineer team listens to customer needs, providing practical and
                    personalized solutions while focusing on reducing investment
                    costs and maximizing value returns. We prioritize design and
                    production by investing in advanced equipment such as automatic
                    fiber laser cutting machines, CNC machine tools, and welding
                    robots. Our comprehensive quality management system, certified
                    ISO9001 in 2015, ensures high-quality products.
                  </p>
                  <p className="packing-description">
                    We also offer exceptional pre-sales and after-sales services
                    through our professional customer service and aftersales teams.
                    Our goal is to provide worry-free service throughout the entire
                    process. As food safety is fundamental, we are committed to
                    enhancing our technology, products, and services to become the
                    most trustworthy brand in the global food processing industry.
                  </p>
                  <div className="common-spacing-top d-flex  flex-column gap-3 gap-md-0 flex-md-row ">
                    <HomePageBtn btnText={"Explore More Details"}  onClick={() => navigate('/AboutUs')} />
                    <div className='d-flex align-items-center social-links' style={{ marginLeft: 40 }}>
                      <a href="https://web.whatsapp.com/" target="_blank">
                        <FaWhatsappSquare size={50} className='whatsapp' style={{ marginRight: 10 }} />
                      </a>
                      <a href="https://www.instagram.com/" target="_blank">
                        <Image src={require('./../assets/images/instagram.png')} className='mx-2' style={{ height: 50, width: 50 }} />
                      </a>
                      <a href="https://www.youtube.com/" target="_blank">
                        <FaYoutubeSquare size={50} className='mx-2 youtube' />
                      </a>
                      <a href="https://www.facebook.com/" target="_blank">
                        <FaFacebookSquare size={50} className='mx-2 facebook' />
                      </a>
                    </div>
                  </div>
                </div>
              </Col>

              <Col sm={12} md={12} lg={6} className="d-flex justify-content-end home-about-image position-relative ">
              </Col>
            </Row>
          </div>
          <div className="main-image-container">
            <img src={require("../assets/images/Video.gif")} className="main-image" />
          </div>
        </div>

      </div>

      <div className='custom-horizontal-line-3' />
      {/* Company Intro Section  */}

      {/* All Categories Section  */}
      <Element name="all_category">
        <div className="container  common-spacing">
          {selectedCategory && (
            <Row className="align-items-center">
              <Col sm={12} md={12} lg={6}>
                <HomeSectionsHeading
                  title={"ALl Categories "}
                  heading={selectedCategory.heading}
                />
                <div className="">
                  <p className="packing-description packing-description-2  mt-3">
                    {selectedCategory.paragraph}
                  </p>
                  <div className="common-spacing-top">
                    <HomePageBtn btnText={"Explore More Details"} onClick={() => navigate(`/${selectedCategory.navigation}`)} />
                  </div>
                </div>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <div className="d-flex justify-content-start align-items-center mb-1 mt-4 mt-lg-0">
                  <h2 className="packing_sample lato-regular text-uppercase">Packing Sample</h2>
                  <img
                    src={require("../assets/images/Lob-Arrow.png")}
                    className="lob_arrow"
                  />
                </div>
                <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start">
                  {selectedCategory.images.map((images, index) => {
                    return (
                      <img
                        className="category_card"
                        key={index}
                        src={images}
                        alt={`Category ${selectedCategory.heading} ${index + 1}`}
                      />
                    )
                  })}
                </div>
              </Col>
            </Row>
          )}

          <div className="d-flex justify-content-between align-items-center gap-4 selected-category-container pt-5 pb-3">
            {categoryKeys.map((v, i) => {
              const isActive = v.trim().toLowerCase() == selectedCategory.heading.trim().toLowerCase();
              return (
                <div className="d-flex align-items-center text-center flex-column position-relative" key={i}>
                  <div
                    onClick={() => handleCategoryClick(v)}
                    className={`packing-card packing-card-${i + 1} d-flex justify-content-center align-items-center ${isActive ? 'active-packing-card' : ''}`}
                  >
                    <div className="text-center">
                      <h2 style={selectedCategoryStyles} className={isActive ? 'lato-bold' : ''}>{v}</h2>
                    </div>
                    {isActive &&
                      <div className="packing-card-shadow" />
                    }
                  </div>
                  {isActive &&
                    <div className="packing-card-line" />
                  }

                </div>
              );
            })}
       
          </div>
        </div>
      </Element>

      <div className="quality-policy-section d-flex align-items-center justify-content-center w-full">
        <div className="container text-center common-spacing">
          <div className="container">
            <h2 className="revo-machine-heading lato-black">
              REVOLUTIONARY MACHINES
            </h2>

            <div className="d-flex  justify-content-center align-items-center gap-5">
              <div className="quality-line "></div>
              <div>
                <h2 className="text-uppercase fs-3 text-primary lato-black quaity-text">
                  Quality Policy
                </h2>
              </div>
              <div className="quality-line"></div>
            </div>

            <p className="text-primary revo-machine-description">
              Quality is the cornerstone of Revolutionary Machines and the
              reason customers choose us. We respect process rules, respond
              quickly to customer needs, get things right the first time, and
              focus on continuous improvement.
            </p>
          </div>
        </div>
      </div>

      <Slider />

    
      <div className=" contact-info-section common-margin-top ">
        <div className="container  contact-info-section-child">
          <ContactComponent />
        </div>
      </div>

      <div className="home-accordion-bg">
        <div className="container common-spacing">
          <HomeSectionsHeading
            title={"Pre answered Questions for your assistance"}
            heading={"Frequently Asked Questions"}
          />
          <div className="mt-4">
            <AccordionComp data={accordantData} />
          </div>
        </div>
      </div>


      <div className="container common-spacing">
        <HomeSectionsHeading
          title={"Subscribe for monthly updates"}
          heading={"our Newsletter"}
        />
        <div className="mt-4 d-flex">
          <input
            placeholder="Email Address"
            className="subscribe-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
          <HomePageBtn
          className='newsletter-btn'
            btnText={loading ? "Subscribing..." : "Subscribe"}
            onClick={handleSubscribe}
            style={{
              backgroundColor: "#EF3C0F",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              fontSize: isMobile ? 14 : 24, // Change font size based on screen width
              width: isMobile ? 180 : 300,  // Change width based on screen width
              border: "none",
              cursor: loading ? "not-allowed" : "pointer",
            }}
            disabled={loading}
          />
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {success && <p style={{ color: 'green' }}>{success}</p>}
      </div>
    </div>
  );
}

export default Home;


