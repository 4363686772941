import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Banner_btn from "../components/Common/Banner_btn";
import HomeSectionsHeading from "../components/Common/HomeSectionsHeading";
import HomePageBtn from "../components/Common/HomePageBtn";
import DataTable_comp from "../components/Common/DataTable_comp";
import {
  SpecialPackingTableData2,
  SpecialPackingTableData3,
  SpecialPackingTableData4,
  SpecialPackingTableData6,
  SpecialPackingTableData7,
  SpecialPackingTableData9,
} from "../utils/Data";
import {
  Element,
  animateScroll as scroll,
  scroller,
} from "react-scroll";
import Divider_comp from "../components/Common/Divider_comp";
import Divider_comp_2 from "../components/Common/Divider_comp_2";
const packing1Card = [
  require("../assets/specialPacking/card/Rectangle 65.png"),
  require("../assets/specialPacking/card/Rectangle 67.png"),
  require("../assets/specialPacking/card/Rectangle 68.png"),
  require("../assets/specialPacking/card/Rectangle 69.png"),
];
const packing2Card = [
  require("../assets/specialPacking/card/Rectangle 63.png"),
  require("../assets/specialPacking/card/Rectangle 70.png"),
  require("../assets/specialPacking/card/Rectangle 71.png"),
  require("../assets/specialPacking/card/Rectangle 72.png"),
];
const packing4Card = [
  require("../assets/specialPacking/card/Rectangle 74.png"),
  require("../assets/specialPacking/card/Rectangle 75.png"),
  require("../assets/specialPacking/card/Rectangle 76.png"),
  require("../assets/specialPacking/card/Rectangle 77.png"),
];

const packing5Card = [
  require("../assets/specialPacking/card/Rectangle 78.png"),
  require("../assets/specialPacking/card/Rectangle 79.png"),
  require("../assets/specialPacking/card/Rectangle 80.png"),
  require("../assets/specialPacking/card/Rectangle 81.png"),
];

const packing6Card = [
  require("../assets/specialPacking/card/Rectangle 82.png"),
  require("../assets/specialPacking/card/Rectangle 83.png"),
  require("../assets/specialPacking/card/Rectangle 84.png"),
  require("../assets/specialPacking/card/Rectangle 85.png"),
];
function SpecialPacking() {

  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = window.location.hash.substring(1);
    if (hash) {
      scroller.scrollTo(hash);
    }
  }, []);
  return (
    <>
      <div className="container-fluid p-0 m-0 packing-banner">
        <div className="container">
          <Row className="h-100 w-100">
            <Col className="contact-banner-content">
              <div className="contact-banner-inner">
                <h1>SPECIAL PURPOSE</h1>
                <div className="d-flex pt-1">
                  <div className="contact-line" />
                  <a>
                    Versatile, High-Speed Packing Solutions for Reliable,
                    <br />
                    Contamination-Free Operation
                  </a>
                </div>
              </div>
            </Col>
            <Col className="contact-banner-content-left">
              <div className="contact-banner-inner-left">
                <Banner_btn
                  right={
                    <>
                      VERTICAL
                      <br />
                      PACKING
                    </>
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <section>
        <Element name="wet-wipe-machine">
          <div className="container common-spacing2">
            <div className="row align-items-center">
              <div className="col col-12 col-lg-6">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={"Wet Wipe Packing Machine"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description">
                    This machine works stably and continuously, making it
                    suitable for packaging a variety of sealed products such as
                    wet wipes, paper towels, alcohol cotton pads, and cotton
                    pads. It features fully automatic bag forming with
                    photoelectric correction to ensure no burrs and fault-free
                    forming. The folding mechanism can be adjusted according to
                    different types, allowing up to 8 vertical folds and 4
                    horizontal folds by altering the width and length of the
                    non-woven fabric. An imported spray pump from Japan improves
                    accuracy by 0.01ML per instance, capable of dispensing a
                    variety of liquids either per bag or collectively in one
                    bag. The entire machine is covered with stainless steel,
                    providing a good appearance, easy cleaning, and durability.
                  </p>
                  <h4 className="packing-category-heading text-uppercase ">
                   Packing Samples
                  </h4>
                  <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start packaging-card-container">
                    {packing1Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-end">
                <div className="text-center text-lg-end mt-4 mt-md-5 mt-lg-0">
                  <Image
                    src={require("../assets/specialPacking/file (29).png")}
                    className="machines-image"
                  />
                </div>
              </div>
            </div>
            <div className="packing-inner-container-left">
              <h4 className="packing-category-heading text-uppercase text-uppercase">
                Main technical parameters
              </h4>
              <div className="table-container">
                <DataTable_comp tableData={SpecialPackingTableData2} />
              </div>
            </div>
          </div>
        </Element>

        <div className="container">
          <Divider_comp />
        </div>


        <Element name="multi-piece-wet-wipes-5-30pcs">
          <div className="container common-spacing2">
            <div className="row">
              <div className="order-2 order-lg-1 col col-12 col-lg-6 d-flex justify-content-center align-items-center justify-content-lg-none">
                <div className="text-center text-lg-end  mt-4 mt-md-5 mt-lg-0">
                  <Image
                    src={require("../assets/specialPacking/file (30).png")}
                    className="machines-image machines-image-padding-left"
                  />
                </div>
              </div>
              <div className="order-1 order-lg-2 col col-12 col-lg-6">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={"Multi piece Wet Wipes Packing Machine (5~30PCS）"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description">
                    This machine works stably and continuously, making it
                    suitable for packaging a variety of sealed products such as
                    wet wipes, paper towels, alcohol cotton pads, and cotton
                    pads. It features fully automatic bag forming with
                    photoelectric correction to ensure no burrs and fault-free
                    forming. The folding mechanism can be adjusted according to
                    different types, allowing up to 8 vertical folds and 4
                    horizontal folds by altering the width and length of the
                    non-woven fabric. An imported spray pump from Japan improves
                    accuracy by 0.01ML per instance, capable of dispensing a
                    variety of liquids either per bag or collectively in one
                    bag. The entire machine is covered with stainless steel,
                    providing a good appearance, easy cleaning, and durability.
                  </p>
                  <h4 className="packing-category-heading text-uppercase  text-uppercase">
                    Packing Samples
                  </h4>
                  <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start packaging-card-container">
                    {packing2Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="row p-0 m-0">
                <div className="col-0  col-lg-6"></div>
                <div className="col-12 col-lg-6 ">
                  <h4 className="packing-category-heading text-uppercase packing-inner-container-left  text-uppercase">
                    Main technical parameters
                  </h4>
                </div>
              </div>
              <div className="table-container packing-inner-container-left">
                <DataTable_comp tableData={SpecialPackingTableData3} />
              </div>
            </div>
          </div>
        </Element>


        <div className="container">
          <Divider_comp_2 />
        </div>


        <Element name="multi-piece-wet-wipes-30-120pcs">
          <div className="container common-spacing2">
            <div className="row">
              <div className="col col-12 col-lg-6">
                <HomeSectionsHeading
                  title={"Advanced Processing Machinery"}
                  heading={"Multi piece Wet Wipes Packing Machine (30~120PCS）"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description">
                    This machine is designed to cut and fold a whole roll of raw
                    paper to a fixed width, add liquid, cut to a specific
                    length, count, stack, and then automatically transfer the
                    products to a packing machine for packaging. The raw
                    materials are handled with an automatic refueling device,
                    tension control, and automatic deviation correction,
                    ensuring that all processes from raw materials to finished
                    products are completed on the machine, making the production
                    process fast and hygienic. The main folding machine and
                    packaging machine can be connected for automatic operation
                    or used separately for producing special specification
                    products, allowing customers to maximize the machine's
                    utility for optimal benefits.
                  </p>
                  <h4 className="packing-category-heading text-uppercase ">
                   Packing Samples
                  </h4>
                  <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start packaging-card-container">
                    {packing2Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-end">
                <div className="text-center text-lg-end  mt-4 mt-md-5 mt-lg-0">
                  <Image
                    src={require("../assets/specialPacking/file (32).png")}
                    className="machines-image"
                  />
                </div>
              </div>
            </div>
            <div className="packing-inner-container-left">
              <h4 className="packing-category-heading text-uppercase  text-uppercase">
                Main technical parameters
              </h4>
              <div className="table-container">
                <DataTable_comp tableData={SpecialPackingTableData4} />
              </div>
            </div>
          </div>
        </Element>


        <div className="container">
          <Divider_comp />
        </div>

        <Element name="automatic-tea-bag-machine">
          <div className="container common-spacing2">
            <div className="row">
              <div className="order-2 order-lg-1 col col-12 col-lg-6 d-flex justify-content-center  align-items-center justify-content-lg-none">
                <div className="text-center text-lg-end  mt-4 mt-md-5 mt-lg-0">
                  <Image
                    src={require("../assets/specialPacking/file (33).png")}
                    className="machines-image machines-image-padding-left"
                  />
                </div>
              </div>
              <div className="order-1 order-lg-2 col col-12 col-lg-6">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={
                    "Automatic TeaBag Machine With Inner andOuter Envelope"
                  }
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description">
                    This machine is suitable for disposable packaging of inner
                    and outer bags of tea, medicinal tea, health tea, grass
                    roots, and other small particles. The packaging length of
                    the inner and outer bags is controlled by a stepping motor,
                    ensuring stable bag length and accurate positioning. The PID
                    temperature controller allows for more precise temperature
                    control. The entire machine is controlled by a PLC, with a
                    man-machine interface display to ensure the product is
                    hygienic and reliable. All accessible materials are made of
                    SUS304 stainless steel, ensuring the sanitation and
                    reliability of the product.
                  </p>
                  <h4 className="packing-category-heading text-uppercase ">
                   Packing Samples
                  </h4>
                  <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start packaging-card-container">
                    {packing4Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="row p-0 m-0">
                <div className="col-0  col-lg-6"></div>
                <div className="col-12 col-lg-6">
                  <h4 className="packing-category-heading text-uppercase packing-inner-container-left  text-uppercase">
                    Main technical parameters
                  </h4>
                </div>
              </div>
              <div className="table-container packing-inner-container-left">
                <DataTable_comp tableData={SpecialPackingTableData6} />
              </div>
            </div>
          </div>
        </Element>

        <div className="container">
          <Divider_comp_2 />
        </div>

        <Element name="tea-bag-packing-machine">
          <div className="container common-spacing2">
            <div className="row align-items-center">
              <div className="col col-12 col-lg-6">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={"Round Tea Bag Packaging Machine"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description">
                    The round shape tea bag packaging machine is suitable for
                    granular materials in crushed tea, substitute tea, and other
                    similar products. It automatically completes functions such
                    as bag making, metering, blanking, sealing, slitting, and
                    counting. The machine uses a PLC to control its entire
                    operation, driving the stepper motor to control bag length.
                    This ensures stable performance, convenient adjustment, and
                    accurate detection.
                  </p>
                  <h4 className="packing-category-heading text-uppercase  ">
                   Packing Samples
                  </h4>
                  <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start packaging-card-container">
                    {packing5Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col col-12 col-lg-6 d-flex align-items-center justify-content-center">
                <div className="text-center text-lg-end  mt-4 mt-md-5 mt-lg-0">
                  <Image
                    src={require("../assets/specialPacking/file (33) 1.png")}
                    className="machines-image"
                  />
                </div>
              </div>
            </div>
            <div className="packing-inner-container-left">
              <h4 className="packing-category-heading text-uppercase  text-uppercase">
                Main technical parameters
              </h4>
              <div className="table-container">
                <DataTable_comp tableData={SpecialPackingTableData7} />
              </div>
            </div>
          </div>
        </Element>


        <div className="container">
          <Divider_comp />
        </div>



        <Element name="pyramid-tea-bag-machine">
          <div className="container common-spacing2">
            <div className="row">
              <div className="order-2 order-lg-1 col col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-none">
                <div className="text-center text-lg-end mt-4 mt-md-5 mt-lg-0">
                  <Image
                    src={require("../assets/specialPacking/file (34).png")}
                    className="machines-image machines-image-padding-left"
                  />
                </div>
              </div>
              <div className="order-1 order-lg-2 col col-12 col-lg-6">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={"Pyramid Tea Bag Packaging Machine"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description">
                    This unit can perform either three sides sealing or triangle
                    tea bag packaging, easily switching between the two shapes
                    with a single bond change. It has a packaging capacity of
                    3000 bags per hour, depending on the packing material. The
                    machine uses nylon film with thread and label as the packing
                    material. It features a precision volumetric metering system
                    that simplifies the transformation of filling material, and
                    can be equipped with an electronic scale metering system
                    based on the material. The Human-Computer Interface and PLC
                    control, along with the Servo motor, allow for easy setting
                    of bag length and operation. The unit includes a main motor
                    overload protection device, automatic adjustment of
                    packaging material tension, and will automatically stop and
                    issue a warning if a problem occurs. It adopts imported
                    ultrasonic sealing technology, ensuring firm sealing and
                    accurate material weighing.
                  </p>
                  <h4 className="packing-category-heading text-uppercase ">
                    Packing Samples
                  </h4>
                  <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start packaging-card-container">
                    {packing6Card.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="row p-0 m-0">
                <div className="col-0 col-lg-6"></div>
                <div className="col-12 col-lg-6">
                  <h4 className="packing-category-heading packing-inner-container-left text-uppercase">
                    Main technical parameters
                  </h4>
                </div>
              </div>
              <div className="table-container packing-inner-container-left">
                <DataTable_comp tableData={SpecialPackingTableData9} />
              </div>
            </div>
          </div>
        </Element>


        <div className="container common-margin-bottom">
          <Divider_comp_2 />
        </div>


      </section>
    </>
  );
}

export default SpecialPacking;
