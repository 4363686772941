import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaBars, FaAngleRight } from 'react-icons/fa';
import { Image } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import { GoDot } from 'react-icons/go';

function MobileHeader() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const navigate = useNavigate();

    const toggleDrawer = () => {
        setDrawerOpen(prevState => !prevState);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    const toggleDropdown = (index) => {
        setDropdownOpen(prevIndex => (prevIndex === index ? null : index));
    };

    return (
        <header className="mobile-header">
            <div className="mobile-header-container">
                <div className='logo-container-mobile'>
                    <Image
                        src={require('../assets/images/Revo-Logo.png')}
                        alt='Revo logo'
                        className='main-logo-mobile'
                        onClick={() => navigate('/')}
                    />
                </div>
                <div
                    className="hamburger-icon"
                    onClick={toggleDrawer}
                    aria-label="Toggle menu"
                >
                    <FaBars />
                </div>
            </div>
            {drawerOpen && (
                <div
                    className={`overlay ${drawerOpen ? 'show' : ''}`}
                    onClick={closeDrawer}
                    aria-label="Close menu"
                ></div>
            )}

            <div className={`mobile-drawer ${drawerOpen ? 'open' : 'close'}`}>
                <ul className="mobile-nav-menu">
                    <li className='d-flex align-items-center justify-content-between drawer-top'>
                        <span>MENU</span>
                        <IoClose
                            className='close-icon-drawer'
                            onClick={closeDrawer}
                            aria-label="Close menu"
                        />
                    </li>

                   
                    <li>
                        <NavLink className={'page-link'} to="/" onClick={closeDrawer}>
                            Home
                        </NavLink>
                    </li>

                
                    <li>
                        <div
                            className='justify-content-between d-flex align-items-center'
                            onClick={() => toggleDropdown(1)}
                            aria-expanded={dropdownOpen === 1}
                        >
                            <NavLink className={'page-link'} to="/PackingSolution" onClick={closeDrawer}>
                                Packing Solution
                            </NavLink>
                            <FaAngleRight className={`angle-right ${dropdownOpen === 1 ? 'rotate' : ''}`} />
                        </div>
                        <ul className={`mobile-dropdown-menu ${dropdownOpen === 1 ? 'open' : 'close'}`}>
                            <li>
                                <NavLink to="/PackingSolution#multi-lane-counting-machine" onClick={closeDrawer}>
                                    <GoDot /> High Speed Multi-Lane Counting And Packing Solution
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/PackingSolution#mix-grain-machine" onClick={closeDrawer}>
                                    <GoDot /> Mixed Grain Nuts Packing Solution
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/PackingSolution#feeding-machine" onClick={closeDrawer}>
                                    <GoDot /> Automatic Feeding & Packing Solution
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/PackingSolution#juice-filling-solution" onClick={closeDrawer}>
                                    <GoDot /> Juice Filling Solution
                                </NavLink>
                            </li>
                        </ul>
                    </li>

                   
                    <li>
                        <div
                            className='justify-content-between d-flex align-items-center'
                            onClick={() => toggleDropdown(2)}
                            aria-expanded={dropdownOpen === 2}
                        >
                            <NavLink className={'page-link'} to="/VerticalPacking" onClick={closeDrawer}>
                                Vertical Packing
                            </NavLink>
                            <FaAngleRight className={`angle-right ${dropdownOpen === 2 ? 'rotate' : ''}`} />
                        </div>
                        <ul className={`mobile-dropdown-menu ${dropdownOpen === 2 ? 'open' : 'close'}`}>
                            <li>
                                <NavLink to="/VerticalPacking#pre-made-bag" onClick={closeDrawer}>
                                    <GoDot /> Premade Bag Packing Machine
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/VerticalPacking#multi-head-weighing-machine" onClick={closeDrawer}>
                                    <GoDot /> Multi-Head Weighing And Packing Machine
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/VerticalPacking#powder-packing-machine" onClick={closeDrawer}>
                                    <GoDot /> Powder Packing Machine
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/VerticalPacking#powder-sachet-machine" onClick={closeDrawer}>
                                    <GoDot /> Powder Sachet Packing Machine
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/VerticalPacking#automatic-feeding-machine" onClick={closeDrawer}>
                                    <GoDot /> Automatic Feeding & Packing Solution
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/VerticalPacking#juice-filling-machine" onClick={closeDrawer}>
                                    <GoDot /> Juice Filling Solution
                                </NavLink>
                            </li>
                        </ul>
                    </li>

                   
                    <li>
                        <div
                            className='justify-content-between d-flex align-items-center'
                            onClick={() => toggleDropdown(3)}
                            aria-expanded={dropdownOpen === 3}
                        >
                            <NavLink className={'page-link'} to="/LiquidAndPasteFilling" onClick={closeDrawer}>
                                Liquid & Paste Filling
                            </NavLink>
                            <FaAngleRight className={`angle-right ${dropdownOpen === 3 ? 'rotate' : ''}`} />
                        </div>
                        <ul className={`mobile-dropdown-menu ${dropdownOpen === 3 ? 'open' : 'close'}`}>
                            <li>
                                <NavLink to="/LiquidAndPasteFilling#paste-filling-machine" onClick={closeDrawer}>
                                    <GoDot /> Automatic Liquid & Paste Filling Machine
                                </NavLink>
                            </li>
                        </ul>
                    </li>

                   
                    <li>
                        <div
                            className='justify-content-between d-flex align-items-center'
                            onClick={() => toggleDropdown(4)}
                            aria-expanded={dropdownOpen === 4}
                        >
                            <NavLink className={'page-link'} to="/PillowPacking" onClick={closeDrawer}>
                                Pillow Packing
                            </NavLink>
                            <FaAngleRight className={`angle-right ${dropdownOpen === 4 ? 'rotate' : ''}`} />
                        </div>
                        <ul className={`mobile-dropdown-menu ${dropdownOpen === 4 ? 'open' : 'close'}`}>
                            <li>
                                <NavLink to="/PillowPacking#up-fil-machine" onClick={closeDrawer}>
                                    <GoDot /> Up Film Pillow Packing Machine
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/PillowPacking#bottom-filling-machine" onClick={closeDrawer}>
                                    <GoDot /> Bottom Film Pillow Packing Machine
                                </NavLink>
                            </li>
                        </ul>
                    </li>

                   
                    <li>
                        <div
                            className='justify-content-between d-flex align-items-center'
                            onClick={() => toggleDropdown(5)}
                            aria-expanded={dropdownOpen === 5}
                        >
                            <NavLink className={'page-link'} to="/OverWrapping" onClick={closeDrawer}>
                                Overwrapping
                            </NavLink>
                            <FaAngleRight className={`angle-right ${dropdownOpen === 5 ? 'rotate' : ''}`} />
                        </div>
                        <ul className={`mobile-dropdown-menu ${dropdownOpen === 5 ? 'open' : 'close'}`}>
                            <li>
                                <NavLink to="/OverWrapping#cellophane-machine" onClick={closeDrawer}>
                                    <GoDot /> Cellophane Wrapping Machine
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/OverWrapping#lbar-machine" onClick={closeDrawer}>
                                    <GoDot /> L Bar Shrink Wrap Machine
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/OverWrapping#sleeve-shrink-machine" onClick={closeDrawer}>
                                    <GoDot /> Sleeve Shrink Wrapping Machine
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/OverWrapping#shrink-machine" onClick={closeDrawer}>
                                    <GoDot /> Shrink Wrapping Machine
                                </NavLink>
                            </li>
                        </ul>
                    </li>

                   
                    <li>
                        <div
                            className='justify-content-between d-flex align-items-center'
                            onClick={() => toggleDropdown(6)}
                            aria-expanded={dropdownOpen === 6}
                        >
                            <NavLink className={'page-link'} to="/SpecialPacking" onClick={closeDrawer}>
                                Special Purpose
                            </NavLink>
                            <FaAngleRight className={`angle-right ${dropdownOpen === 6 ? 'rotate' : ''}`} />
                        </div>
                        <ul className={`mobile-dropdown-menu ${dropdownOpen === 6 ? 'open' : 'close'}`}>
                            <li>
                                <NavLink to="/SpecialPacking#wet-wipe-machine" onClick={closeDrawer}>
                                    <GoDot /> Wet Wipe Packing Machine
                                </NavLink>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <div
                            className='justify-content-between d-flex align-items-center'
                            onClick={() => toggleDropdown(7)}
                            aria-expanded={dropdownOpen === 7}
                        >
                            <NavLink className={'page-link'} to="/VacuumPacking" onClick={closeDrawer}>
                                Vaccum Packing
                            </NavLink>
                            <FaAngleRight className={`angle-right ${dropdownOpen === 7 ? 'rotate' : ''}`} />
                        </div>
                        <ul className={`mobile-dropdown-menu ${dropdownOpen === 7 ? 'open' : 'close'}`}>
                            <li>
                                <NavLink to="/VacuumPacking#stretch-film-machine" onClick={closeDrawer}>
                                    <GoDot /> Stretch
                                    Film Packing Machine
                                </NavLink>
                            </li>
                        </ul>
                    </li>


                    <li>
                        <div
                            className='justify-content-between d-flex align-items-center'
                            onClick={() => toggleDropdown(8)}
                            aria-expanded={dropdownOpen === 8}
                        >
                            <NavLink className={'page-link'} to="/PowderProcessing" onClick={closeDrawer}>
                                Powder processing
                            </NavLink>
                        </div>

                    </li>
                    
                    <li>
                        <NavLink className={'page-link'} to="/AboutUs" onClick={closeDrawer}>
                            About Us
                        </NavLink>
                    </li>
                    
                    <li>
                        <NavLink className={'page-link'} to="/ContactUs" onClick={closeDrawer}>
                            Contact
                        </NavLink>
                    </li>
                </ul>
            </div>

        </header>
    );
}

export default MobileHeader;    