import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Banner_btn from "../components/Common/Banner_btn";
import HomeSectionsHeading from "../components/Common/HomeSectionsHeading";
import DataTable_comp from "../components/Common/DataTable_comp";
import { LiquidPasteFillingTableData } from "../utils/Data";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Divider_comp_2 from "../components/Common/Divider_comp_2";
import Divider_comp from "../components/Common/Divider_comp";

const liquid1 = [
  require("../assets/liquidPasteFilling/card/Rectangle 2.png"),
  require("../assets/liquidPasteFilling/card/Rectangle 3.png"),
  require("../assets/liquidPasteFilling/card/Rectangle 4.png"),
  require("../assets/liquidPasteFilling/card/Rectangle 5.png"),
  require("../assets/liquidPasteFilling/card/Rectangle 6.png"),
  require("../assets/liquidPasteFilling/card/Rectangle 7.png"),
  require("../assets/liquidPasteFilling/card/Rectangle 8.png"),
];

function LiquidAndPasteFilling() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = window.location.hash.substring(1);
    if (hash) {
      scroller.scrollTo(hash);
    }
  }, []);
  return (
    <>
      <div className="container-fluid p-0 m-0 packing-banner">
        <div className="container">
          <Row className="h-100 w-100">
            <Col className="contact-banner-content">
              <div className="contact-banner-inner">
                <h1>LIQUID & PASTE FILLING</h1>
                <div className="d-flex pt-1">
                  <div className="contact-line" />
                  <a>
                    Versatile, High-Speed Packing Solutions for Reliable,
                    <br />
                    Contamination-Free Operation
                  </a>
                </div>
              </div>
            </Col>
            <Col className="contact-banner-content-left">
              <div className="contact-banner-inner-left">
                <Banner_btn
                  right={
                    <>
                      LIQUID
                      <br />
                      & PASTE
                      <br />
                      FILLING
                    </>
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <section>
        <Element name="paste-filling-machine">
          <div className="container common-spacing2">
            <div className="row align-items-center">
              <div className="col col-md-12">
                <HomeSectionsHeading
                  title={"Efficient Automated Packing"}
                  heading={"Automatic filling machine"}
                />
                <div className="packing-inner-container-left">
                  <p className="packing-description mt-3">
                    This series of liquid filling machines is suitable for daily
                    chemical, food, oil, medicine, pesticide, and other
                    industries. It can automatically fill paste, semi-fluid
                    viscous products, sauces, honey, jam, and various other
                    products. The piston stroke is controlled by cylinder
                    pushing, offering high filling precision, convenient
                    installation and debugging, simple cleaning and maintenance,
                    and no dripping. Additionally, it features an anti-drip
                    device, lifting filling, variable filling speed from fast to
                    slow, and filling while producing. All material contact
                    parts are heated and insulated. Customizable options include
                    a double-layer hopper, touch screen, protective cover, and
                    functions for liquid level control and stirring.
                  </p>
                  <h4 className="packing-category-heading text-uppercase">
                    Packing Samples
                  </h4>
                  <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start packaging-card-container">
                    {liquid1.map((v, i) => {
                      return (
                        <div key={i}>
                          <Image src={v} className="card-image" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Element>

        <div className="container  d-flex justify-content-center">
          <div className="row">
            <div className="col-md-6">
              <Image
                src={require("../assets/liquidPasteFilling/file 1.png")}
                className="machines-image machines-image-padding-right"
              />
            </div>
            <div className="col-md-6 ">
              <Image
                src={require("../assets/liquidPasteFilling/file 2.png")}
                className="machines-image machines-image-padding-right"
              />
            </div>
          </div>
        </div>

        <div className="container common-spacing-side d-flex justify-content-center">
          <div className="row packing-inner-container-left">
            <div className="col">
              <h4 className="packing-category-heading text-uppercase">
                Main technical parameters
              </h4>
              <div className="table-container">
                <DataTable_comp tableData={LiquidPasteFillingTableData} />
              </div>
            </div>
          </div>
        </div>

        <div className="container common-margin-bottom mt-4">
          <Divider_comp />
        </div>
      </section>
    </>
  );
}

export default LiquidAndPasteFilling;
