import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Banner_btn from "../components/Common/Banner_btn";
import HomeSectionsHeading from "../components/Common/HomeSectionsHeading";
import HomePageBtn from "../components/Common/HomePageBtn";
import DataTable_comp from "../components/Common/DataTable_comp";
import { PillowPackingTable1, PillowPackingTable2, VerticalPackingTableData2 } from "../utils/Data";
import { Element, scroller } from "react-scroll";
import Divider_comp from "../components/Common/Divider_comp";
import Divider_comp_2 from "../components/Common/Divider_comp_2";

const packing1Card = [
  require('../assets/packing/card/pillow-packing/PP-1.png'),
  require('../assets/packing/card/pillow-packing/PP-2.png'),
  require('../assets/packing/card/pillow-packing/PP-3.png'),
  require('../assets/packing/card/pillow-packing/PP-4.png'),
]


const packing2Card = [
  require('../assets/packing/card/pillow-packing/PP-5.png'),
  require('../assets/packing/card/pillow-packing/PP-6.png'),
  require('../assets/packing/card/pillow-packing/PP-7.png'),
  require('../assets/packing/card/pillow-packing/PP-8.png'),
]
const PillowPacking = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = window.location.hash.substring(1);
    if (hash) {
      scroller.scrollTo(hash);
    }
  }, []);
  return (
    <>
      <div className="container-fluid p-0 m-0 packing-banner">
        <div className="container">
          <Row className="h-100 w-100">
            <Col className="contact-banner-content">
              <div className="contact-banner-inner">
                <h1>PILLOW PACKING</h1>
                <div className="d-flex pt-1">
                  <div className="contact-line" />
                  <a>
                    Versatile, High-Speed Packing Solutions for Reliable,<br />
                    Contamination-Free Operation
                  </a>
                </div>
              </div>
            </Col>
            <Col className="contact-banner-content-left">
              <div className="contact-banner-inner-left">
                <Banner_btn
                  right={
                    <>
                      PILLOW
                      <br />
                      PACKING
                    </>
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Element name="up-fil-machine">
        <div className="container common-spacing2">
          <div className='row align-items-center'>
            <div className='col col-12 col-lg-6'>
              <HomeSectionsHeading
                title={"Efficient Automated Packing"}
                heading={"Up Film Pillow Packing Machine"}
              />
              <div className='packing-inner-container-left'>
                <p className='packing-description mt-3'>
                  This machine is suitable for packing a variety of goods and products, including pharmaceuticals, toys, and spare parts. It features a three-servo system where the bag length is set and cut without the need to adjust for empty running, saving time and film. The color touch screen allows for convenient and quick parameter setting. The machine includes a fault self-diagnosis function with a clear fault display. High-sensitivity photoelectric color code tracking and digital input for sealing and cutting positions ensure more accurate sealing and cutting. The independent PID temperature control is suitable for all kinds of packaging materials. Additionally, all controls are managed by software, making function adjustments and technology upgrades easy, ensuring the machine never falls behind.
                </p>
                <h4 className='packing-category-heading text-uppercase '>Packing Samples</h4>
                <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start packaging-card-container">
                  {packing1Card.map((v, i) => {
                    return (
                      <div key={i}>
                        <Image src={v} className='card-image' />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className='col col-12 col-lg-6 d-flex flex-column justify-content-between'>
              <div className='text-center text-lg-end mt-4 mt-md-5 mt-lg-0'>
                <Image src={require('../assets/packing/file (22).png')} className='machines-image ' />
              </div>
            </div>
          </div>
          <div className='row packing-inner-container-left'>
            <div className='col'>
              <h4 className='packing-category-heading text-uppercase'>Main technical parameters</h4>
              <div className='table-container'>
                <DataTable_comp tableData={PillowPackingTable1} />
              </div>
            </div>
          </div>
        </div>
      </Element>

      <div className="container">
        <Divider_comp />
      </div>

      <Element name="bottom-filling-machine">
        <div className="container common-spacing2">
          <div className='row align-items-center'>
            <div className='order-2 order-md-1 col col-12 col-lg-6 d-flex flex-column justify-content-between'>
              <div className='text-center text-lg-end mt-4 mt-md-5 mt-lg-0'>
                <Image src={require('../assets/packing/file (23).png')} className='machines-image machines-image-padding-left' />
              </div>
            </div>
            <div className='order-1 order-md-2 col col-12 col-lg-6'>
              <HomeSectionsHeading
                title={"Efficient Automated Packing"}
                heading={"Down Film Pillow Packing Machine"}
              />
              <div className='packing-inner-container-left'>
                <p className='packing-description mt-3'>
                  This machine is suitable for packing a wide range of goods and products, including pharmaceuticals, toys, and spare parts. It features a three-servo system that sets and cuts the bag length without needing to adjust for empty running, saving both time and film. The color touch screen allows for convenient and quick parameter setting. The machine also includes a fault self-diagnosis function with a clear fault display. High-sensitivity photoelectric color code tracking and digital input ensure more accurate sealing and cutting positions. The independent PID temperature control is ideal for various packaging materials. All controls are managed by software, making function adjustments and technology upgrades easy, ensuring the machine stays up-to-date.                                </p>
                <h4 className='packing-category-heading text-uppercase'>Packing Samples</h4>
                <div className="d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start packaging-card-container">
                  {packing2Card.map((v, i) => {
                    return (
                      <div key={i}>
                        <Image src={v} className='card-image' />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

          </div>
          <div className="">
            <div className="row">
              <div className="col-0 col-lg-6"></div>
              <div className="col-12 col-lg-6">
                <h4 className="packing-category-heading packing-inner-container-left text-uppercase">
                  Main technical parameters
                </h4>
              </div>
            </div>
            <div className="table-container packing-inner-container-left">
              <DataTable_comp tableData={PillowPackingTable2} />
            </div>
          </div>
        </div>
      </Element>

      <div className="container common-margin-bottom">
        <Divider_comp_2 />
      </div>
    </>
  );
};

export default PillowPacking;
