import React from 'react';
import Marquee from 'react-fast-marquee';

function TopMovingSlider() {
  const text = "Revolutionary Machines is a leading professional provider of food packaging equipment in the United Kingdom, and is committed to provide competitive total full-process solutions";

  return (
    <Marquee className='marguee-container' delay={1}>
      <div className='marquee-dot'/>{text}<div className='marquee-dot'/>{text}
    </Marquee>
  );
}

export default TopMovingSlider;
