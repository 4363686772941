import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Banner_btn from "../components/Common/Banner_btn";
import HomeSectionsHeading from "../components/Common/HomeSectionsHeading";
import HomePageBtn from "../components/Common/HomePageBtn";
import { Element, scroller } from "react-scroll";
import Divider_comp from "../components/Common/Divider_comp";
import Divider_comp_2 from "../components/Common/Divider_comp_2";

function PackingSolution() {
  const packing1Card = [
    require("../assets/packing/card/Rectangle 63.png"),
    require("../assets/packing/card/Rectangle 65.png"),
    require("../assets/packing/card/Rectangle 67.png"),
    require("../assets/packing/card/Rectangle 68.png"),
  ];

  const packing2Card = [
    require("../assets/packing/card/packing-solution/ps-2 (1).png"),
    require("../assets/packing/card/packing-solution/ps-2 (2).png"),
    require("../assets/packing/card/packing-solution/ps-2 (3).png"),
    require("../assets/packing/card/packing-solution/ps-2 (4).png"),
  ];

  const packing3Card = [
    require("../assets/packing/card/packing-solution/ps-3 (1).png"),
    require("../assets/packing/card/packing-solution/ps-3 (2).png"),
    require("../assets/packing/card/packing-solution/ps-3 (3).png"),
    require("../assets/packing/card/packing-solution/ps-3 (4).png"),
  ];

  const packing4Card = [
    require("../assets/packing/card/packing-solution/ps-4 (4).png"),
    require("../assets/packing/card/packing-solution/ps-4 (5).png"),
    require("../assets/packing/card/packing-solution/ps-4 (6).png"),
    require("../assets/packing/card/packing-solution/ps-4 (7).png"),
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = window.location.hash.substring(1);
    if (hash) {
      scroller.scrollTo(hash);
    }
  }, []);
  return (
    <>
      <div className="container-fluid p-0 m-0 packing-banner">
        <div className="container">
          <Row className="h-100 w-100 banner-container">
            <Col className="contact-banner-content">
              <div className="contact-banner-inner">
                <h1>PACKING SOLUTIONS</h1>
                <div className="d-flex pt-1">
                  <div className="contact-line" />
                  <a>
                    Versatile, High-Speed Packing Solutions for Reliable, <br />
                    Contamination-Free Operation
                  </a>
                </div>
              </div>
            </Col>
            <Col className="contact-banner-content-left">
              <div className="contact-banner-inner-left">
                <Banner_btn
                  right={
                    <>
                      PACKING
                      <br />
                      SOLUTIONS
                    </>
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Element name="multi-lane-counting-machine">
        <div className="container common-spacing2">
          <div className="row justify-content-center">
            <div className="col col-lg-6">
              <HomeSectionsHeading
                title={"Efficient Automated Packing"}
                heading={"High speed Multi lane Counting and Packing Solution"}
              />
              <div className="packing-inner-container-left">
                <p className="packing-description mt-md-3 ">
                  This equipment is widely used for packaging small packages and
                  large batches, including products such as coffee powder,
                  honey, and ketchup. The packing procedures include strip
                  packing, numerical-material packing, and big bag packing.
                  Customized production lines can handle different materials
                  like liquids, powders, and particles. The number of packages
                  can be flexibly adjusted based on the size of the finished
                  products, ensuring accurate counting. With fast packaging
                  speeds, high output, and a compact structure, the equipment
                  enables intelligent packaging with minimal human intervention.
                </p>
                <h4 className="packing-category-heading text-uppercase">
                 Packing Samples
                </h4>
                <div className=" d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start">
                  {packing1Card.map((v, i) => {
                    return (
                      <div key={i}>
                        <Image src={v} className="card-image" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center gap-0  mt-5 mt-md-0">
              <div className="text-center text-lg-end mt-md-5 mt-lg-0">
                <Image
                  src={require("../assets/packing/file (9).png")}
                  className="machines-image"
                />
              </div>
              <div className="text-center text-lg-end mt-md-0 mt-lg-0">
                <Image
                  src={require("../assets/packing/file (10).png")}
                  className="machines-image"
                />
              </div>
            </div>
          </div>
        </div>
      </Element>

      <div className="container">
        <Divider_comp />
      </div>

      <Element name="mix-grain-machine">
        <div className="container common-spacing2">
          <div className="row">
            <div className="order-2 order-md-1 col col-lg-6 d-flex justify-content-center  align-items-center justify-content-lg-none mt-5 mt-md-0">
              <div className="text-center text-lg-end mt-md-5 mt-lg-0">
                <Image
                  src={require("../assets/packing/file (11).png")}
                  className="machines-image"
                />
              </div>
            </div>
            <div className="order-1 order-md-2 col col-12 col-lg-6">
              <HomeSectionsHeading
                title={"Efficient Automated Packing"}
                heading={"Mixed Grain Nuts Packing Solution"}
              />
              <div className="packing-inner-container-left">
                <p className="packing-description mt-3">
                  This equipment is widely used for packaging small packages and
                  large batches, including products such as coffee powder,
                  honey, and ketchup. The packing procedures include strip
                  packing, numerical-material packing, and big bag packing.
                  Customized production lines can handle different materials
                  like liquids, powders, and particles. The number of packages
                  can be flexibly adjusted based on the size of the finished
                  products, ensuring accurate counting. With fast packaging
                  speeds, high output, and a compact structure, the equipment
                  enables intelligent packaging with minimal human intervention.
                </p>
                <h4 className="packing-category-heading text-uppercase">
                  Packing Samples
                </h4>
                <div className=" d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start">
                  {packing2Card.map((v, i) => {
                    return (
                      <div key={i}>
                        <Image src={v} className="card-image" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>

      <div className="container">
        <Divider_comp_2 />
      </div>

      <Element name="feeding-machine">
        <div className="container common-spacing2">
          <div className="row">
            <div className="col col-12 col-lg-6">
              <HomeSectionsHeading
                title={"Efficient Automated Packing"}
                heading={"Automatic Feeding & Packing Solution"}
              />
              <div className="packing-inner-container-left">
                <p className="packing-description mt-3">
                  Primarily suitable for industries such as food, daily
                  necessities, chemicals, pharmaceuticals, and hardware, this
                  system ensures the efficient transport of square, round bars,
                  and other products throughout the entire production line,
                  minimizing manual handling and preventing secondary
                  contamination of food. It can seamlessly integrate with the
                  front-end production line, with a precise design that avoids
                  material deformation due to compression. In cases where
                  material becomes jammed, stacked, or fails to turn properly,
                  it can be automatically cleared without stopping, ensuring
                  continuous and stable production and packaging. The system
                  uses multi-stage frequency conversion speed regulation and
                  servo drive control, offering simple, convenient operation,
                  stable performance, high control accuracy, and low energy
                  consumption. Constructed with food-grade stainless steel and
                  aluminum alloy, the entire line achieves full automation in
                  material transportation, finishing, arrangement, and
                  packaging, thereby eliminating manual handling and preventing
                  secondary food contamination.
                </p>
                <h4 className="packing-category-heading text-uppercase">
                 Packing Samples
                </h4>
                <div className=" d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start">
                  {packing3Card.map((v, i) => {
                    return (
                      <div key={i}>
                        <Image src={v} className="card-image" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="col col-lg-6 d-flex justify-content-center align-items-center justify-content-lg-none mt-5 mt-md-0">
              <div className="text-center text-lg-end mt-md-5 mt-lg-0">
                <Image
                  src={require("../assets/packing/file (12).png") }
                  className="machines-image machines-image-padding-left"
                />
              </div>
            </div>
          </div>
        </div>
      </Element>

      <div className="container">
        <Divider_comp />
      </div>

      <Element name="juice-filling-solution">
        <div className="container common-spacing2">
          <div className="row">
            <div className="order-2 order-md-2 col col-12 col-lg-6 d-flex flex-column justify-content-center mt-5 mt-md-0">
              <div className="text-center text-lg-end mt-md-5 mt-lg-0">
                <Image
                  src={require("../assets/packing/file (13).png")}
                  className="machines-image"
                />
              </div>
              <div className="text-center text-lg-end mt-md-5 mt-lg-0">
                <Image
                  src={require("../assets/packing/file (14).png")}
                  className="machines-image"
                />
              </div>
            </div>
            <div className="order-1 order-md-2 col col-12 col-lg-6">
              <HomeSectionsHeading
                title={"Efficient Automated Packing"}
                heading={"Juice Filling Solution pACKING machine"}
              />
              <div className="packing-inner-container-left">
                <p className="packing-description mt-3">
                  This system is ideal for filling various semi-fluid pastes
                  such as honey, syrup, jam, flower sauce, cheese sauce, tomato
                  sauce, and chili sauce. It utilizes a piston mechanism and
                  combines mechanical and electrical movements within the body,
                  controlled by a PLC, ensuring stable equipment operation and
                  easy cleaning. The system includes a bottle non-filling
                  function and an anti-drip device to prevent drips during
                  filling and drawing. Additionally, without needing to change
                  parts, the system can be quickly adjusted to accommodate
                  different shapes and specifications of bottles, providing
                  strong adaptability.
                </p>
                <h4 className="packing-category-heading text-uppercase">
                 Packing Samples
                </h4>
                <div className=" d-flex flex-wrap flex-md-row justify-content-center justify-content-between align-items-start">
                  {packing4Card.map((v, i) => {
                    return (
                      <div key={i}>
                        <Image src={v} className="card-image" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>

      <div className="container common-margin-bottom">
        <Divider_comp_2 />
      </div>
    </>
  );
}

export default PackingSolution;
