import React from 'react'

function Banner_btn({ left = "HOME", right = "CONTACT US" }) {
    return (
        <div className='btn_1'>
            <a>{left}</a>
            <div style={{ width: '2px', height: '20px', backgroundColor: 'black', margin: 10 }} />
            <a>{right}</a>
        </div>
    )
}

export default Banner_btn