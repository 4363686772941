export const VerticalPackingTableData1 = {
  headings: [
    "MODEL",
    "BAG MATERIAL",
    "PACKING RANGE",
    "ACCURACY",
    "BAG SIZE",
    "PACKING SPEED",
    "VOLTAGE",
    "POWER",
    "AIR CONSUMPTION",
  ],
  rows: [
    [
      { value: "CK-GD8-200", colspan: 1, rowspan: 1 },
      { value: "COMPOSITE FILM", colspan: 1, rowspan: 1 },
      { value: "10-1000G", colspan: 1, rowspan: 1 },
      { value: "± 1", colspan: 1, rowspan: 1 },
      { value: "W:100-210MM L:100-350MM", colspan: 1, rowspan: 1 },
      { value: "10-50BAG/MIN", colspan: 1, rowspan: 1 },
      { value: "380V THREE PHASE 50HZ", colspan: 1, rowspan: 1 },
      { value: "7KW", colspan: 1, rowspan: 1 },
      { value: "0.6M3/MIN", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const VerticalPackingTableData2 = {
  headings: ["MODEL", "CK-LK1050", "CK-LK720", "CK-LK520", "CK-LK420"],
  rows: [
    [
      { value: "FILM WIDTH", colspan: 1, rowspan: 1 },
      { value: "MAX 1050mm", colspan: 1, rowspan: 1 },
      { value: "MAX 1050mm", colspan: 1, rowspan: 1 },
      { value: "MAX 1050mm", colspan: 1, rowspan: 1 },
      { value: "MAX 1050mm", colspan: 1, rowspan: 1 },
    ],

    [
      { value: "BAG WIDTH", colspan: 1, rowspan: 1 },
      { value: "100-500mm", colspan: 1, rowspan: 1 },
      { value: "100-500mm", colspan: 1, rowspan: 1 },
      { value: "100-500mm", colspan: 1, rowspan: 1 },
      { value: "100-500mm", colspan: 1, rowspan: 1 },
    ],

    [
      { value: "BAG LENGTH", colspan: 1, rowspan: 1 },
      { value: "150-700mm", colspan: 1, rowspan: 1 },
      { value: "150-700mm", colspan: 1, rowspan: 1 },
      { value: "150-700MM", colspan: 1, rowspan: 1 },
      { value: "150-700mm", colspan: 1, rowspan: 1 },
    ],

    [
      { value: "PACKING RANGE", colspan: 1, rowspan: 1 },
      { value: "5-25 BAGS/MIN", colspan: 1, rowspan: 1 },
      { value: "5-25 BAGS/MIN", colspan: 1, rowspan: 1 },
      { value: "5-25 BAGS/MIN", colspan: 1, rowspan: 1 },
      { value: "5-25 BAGS/MIN", colspan: 1, rowspan: 1 },
    ],

    [
      { value: "PACKING RANGE", colspan: 1, rowspan: 1 },
      { value: "300-5000ML", colspan: 1, rowspan: 1 },
      { value: "300-5000ML", colspan: 1, rowspan: 1 },
      { value: "300-5000ML", colspan: 1, rowspan: 1 },
      { value: "300-5000ML", colspan: 1, rowspan: 1 },
    ],

    [
      { value: "FILM THICKNESS", colspan: 1, rowspan: 1 },
      { value: "0.04-0.10MM", colspan: 1, rowspan: 1 },
      { value: "0.04-0.10MM", colspan: 1, rowspan: 1 },
      { value: "0.04-0.10MM", colspan: 1, rowspan: 1 },
      { value: "0.04-0.10MM", colspan: 1, rowspan: 1 },
    ],

    [
      { value: "POWER", colspan: 1, rowspan: 1 },
      { value: "4.2KW/220V 50-60HZ", colspan: 1, rowspan: 1 },
      { value: "4.2KW/220V 50-60HZ", colspan: 1, rowspan: 1 },
      { value: "4.2KW/220V 50-60HZ", colspan: 1, rowspan: 1 },
      { value: "4.2KW/220V 50-60HZ", colspan: 1, rowspan: 1 },
    ],

    [
      { value: "MACHINE SIZE", colspan: 1, rowspan: 1 },
      { value: "2090*1870*2910MM", colspan: 1, rowspan: 1 },
      { value: "2090*1870*2910MM", colspan: 1, rowspan: 1 },
      { value: "2090*1870*2910MM", colspan: 1, rowspan: 1 },
      { value: "2090*1870*2910MM", colspan: 1, rowspan: 1 },
    ],

    [
      { value: "MACHINE WEIGHT", colspan: 1, rowspan: 1 },
      { value: "1200KG", colspan: 1, rowspan: 1 },
      { value: "1200KG", colspan: 1, rowspan: 1 },
      { value: "1200KG", colspan: 1, rowspan: 1 },
      { value: "1200KG", colspan: 1, rowspan: 1 },
    ],

    [
      { value: "AIR CONSUMPTION", colspan: 1, rowspan: 1 },
      { value: "1.5M3/MIN 0.8MPA", colspan: 1, rowspan: 1 },
      { value: "1.5M3/MIN 0.8MPA", colspan: 1, rowspan: 1 },
      { value: "1.5M3/MIN 0.8MPA", colspan: 1, rowspan: 1 },
      { value: "1.5M3/MIN 0.8MPA", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const VerticalPackingTableData3 = {
  headings: ["MODEL NUMBER", "MLP-480-04", "MLP-480-06"],
  rows: [
    // Row 1
    [
      { value: "PACKING SPEED", colspan: 1, rowspan: 1 },
      { value: "10-60 BAGS/MIN", colspan: 1, rowspan: 1 },
      { value: "5-50 BAGS/MIN", colspan: 1, rowspan: 1 },
    ],
    // Row 2
    [
      { value: "BAG WEIGHT", colspan: 1, rowspan: 1 },
      { value: "100-3000G", colspan: 1, rowspan: 1 },
      { value: "100-5000G", colspan: 1, rowspan: 1 },
    ],
    // Row 3
    [
      { value: "BAG SIZE", colspan: 1, rowspan: 1 },
      { value: "L:100-350 W:90-250MM", colspan: 1, rowspan: 1 },
      { value: "L:80-420 W:110-350MM", colspan: 1, rowspan: 1 },
    ],
    // Row 4
    [
      { value: "FILM WIDTH", colspan: 1, rowspan: 1 },
      { value: "≤520MM", colspan: 1, rowspan: 1 },
      { value: "≤720MM", colspan: 1, rowspan: 1 },
    ],
    // Row 5
    [
      { value: "VOLTAGE", colspan: 1, rowspan: 1 },
      { value: "220V/50-60HZ", colspan: 1, rowspan: 1 },
      { value: "220V/50-60HZ", colspan: 1, rowspan: 1 },
    ],
    // Row 6
    [
      { value: "WEIGHT", colspan: 1, rowspan: 1 },
      { value: "700KG", colspan: 1, rowspan: 1 },
      { value: "800KG", colspan: 1, rowspan: 1 },
    ],
    // Row 7
    [
      { value: "POWER", colspan: 1, rowspan: 1 },
      { value: "4.0KW", colspan: 1, rowspan: 1 },
      { value: "6.0KW", colspan: 1, rowspan: 1 },
    ],
    // Row 8
    [
      { value: "DIMENSION", colspan: 1, rowspan: 1 },
      { value: "1680*1350*1800MM", colspan: 1, rowspan: 1 },
      { value: "1780*1350*1800MM", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const VerticalPackingTableData4 = {
  headings: [
    "Model",
    "Voltage & Power",
    "Packing Range",
    "Film Width",
    "Bag Size",
    "Machine Size",
    "Weight",
    "Film Thickness",
  ],
  rows: [
    // Row 1
    [
      { value: "CK-LF500", colspan: 1, rowspan: 1 },
      { value: "220V/1.8KW", colspan: 1, rowspan: 1 },
      { value: "200-1000ML", colspan: 1, rowspan: 1 },
      { value: "180-420MM", colspan: 1, rowspan: 1 },
      { value: "L:30-300MM W:80-200MM", colspan: 1, rowspan: 1 },
      { value: "820*980*2100MM", colspan: 1, rowspan: 1 },
      { value: "580KG", colspan: 1, rowspan: 1 },
      { value: "0.03-0.10MM", colspan: 1, rowspan: 1 },
    ],
    // Row 2
    [
      { value: "CK-LF350", colspan: 1, rowspan: 1 },
      { value: "220V/1.8KW", colspan: 1, rowspan: 1 },
      { value: "50-300ML", colspan: 1, rowspan: 1 },
      { value: "80-300MM", colspan: 1, rowspan: 1 },
      { value: "L:40-280MM W:30-140MM", colspan: 1, rowspan: 1 },
      { value: "820*980*1980MM", colspan: 1, rowspan: 1 },
      { value: "400KG", colspan: 1, rowspan: 1 },
      { value: "0.03-0.07MM", colspan: 1, rowspan: 1 },
    ],
    // Row 3
    [
      { value: "CK-LF280", colspan: 1, rowspan: 1 },
      { value: "220V/1.8KW", colspan: 1, rowspan: 1 },
      { value: "10-120ML", colspan: 1, rowspan: 1 },
      { value: "60-280MM", colspan: 1, rowspan: 1 },
      { value: "L:30-180MM W:20-130MM", colspan: 1, rowspan: 1 },
      { value: "720*980*1680MM", colspan: 1, rowspan: 1 },
      { value: "350KG", colspan: 1, rowspan: 1 },
      { value: "0.03-0.07MM", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const VerticalPackingTableData5 = {
  headings: [
    "MODEL",
    "POWER",
    "PACKAGING RANGE",
    "FILM WIDTH",
    "BAG SIZE",
    "MACHINE SIZE",
    "MACHINE WEIGHT",
    "FILM THICKNESS",
  ],
  rows: [
    // Row 1
    [
      { value: "CK-LK320", colspan: 1, rowspan: 1 },
      { value: "220V/1.8KW", colspan: 1, rowspan: 1 },
      { value: "10-100ML", colspan: 1, rowspan: 1 },
      { value: "80-280MM", colspan: 1, rowspan: 1 },
      { value: "L:30-180MM W:30-130MM", colspan: 1, rowspan: 1 },
      { value: "820*980*1680MM", colspan: 1, rowspan: 1 },
      { value: "320KG", colspan: 1, rowspan: 1 },
      { value: "0.03MM-0.07MM", colspan: 1, rowspan: 1 },
    ],
    // Row 2
    [
      { value: "CK-LK350", colspan: 1, rowspan: 1 },
      { value: "220V/1.8KW", colspan: 1, rowspan: 1 },
      { value: "50-300ML", colspan: 1, rowspan: 1 },
      { value: "80-300MM", colspan: 1, rowspan: 1 },
      { value: "L:30-280MM W:30-140MM", colspan: 1, rowspan: 1 },
      { value: "820*980*1680MM", colspan: 1, rowspan: 1 },
      { value: "350KG", colspan: 1, rowspan: 1 },
      { value: "0.03MM-0.07MM", colspan: 1, rowspan: 1 },
    ],
    // Row 3
    [
      { value: "CK-LK480", colspan: 1, rowspan: 1 },
      { value: "220V/1.8KW", colspan: 1, rowspan: 1 },
      { value: "200-1000ML", colspan: 1, rowspan: 1 },
      { value: "180-420MM", colspan: 1, rowspan: 1 },
      { value: "L:40-300MM W:80-200MM", colspan: 1, rowspan: 1 },
      { value: "900*1100*2080MM", colspan: 1, rowspan: 1 },
      { value: "450KG", colspan: 1, rowspan: 1 },
      { value: "0.04MM-0.08MM", colspan: 1, rowspan: 1 },
    ],
  ],
};
export const VerticalPackingTableData6 = {
  headings: ["MODEL NUMBER", "MLP-480-04", "MLP-480-06", "MLP-480-08"],
  rows: [
    // Row 1
    [
      { value: "BAG LENGTH (MM)", colspan: 1, rowspan: 1 },
      { value: "70-200", colspan: 1, rowspan: 1 },
      { value: "70-200", colspan: 1, rowspan: 1 },
      { value: "70-200", colspan: 1, rowspan: 1 },
    ],
    // Row 2
    [
      { value: "BAG WIDTH (MM)", colspan: 1, rowspan: 1 },
      { value: "16-50", colspan: 1, rowspan: 1 },
      { value: "16-35", colspan: 1, rowspan: 1 },
      { value: "16-25", colspan: 1, rowspan: 1 },
    ],
    // Row 3
    [
      { value: "FILM WIDTH (MM)", colspan: 1, rowspan: 1 },
      { value: "MAX 480", colspan: 1, rowspan: 1 },
      { value: "MAX 480", colspan: 1, rowspan: 1 },
      { value: "MAX 480", colspan: 1, rowspan: 1 },
    ],
    // Row 4
    [
      { value: "LANES NUMBER", colspan: 1, rowspan: 1 },
      { value: "4", colspan: 1, rowspan: 1 },
      { value: "6", colspan: 1, rowspan: 1 },
      { value: "8", colspan: 1, rowspan: 1 },
    ],
    // Row 5
    [
      { value: "SPEED (BAG/MIN)", colspan: 1, rowspan: 1 },
      { value: "120-180", colspan: 1, rowspan: 1 },
      { value: "180-270", colspan: 1, rowspan: 1 },
      { value: "240-360", colspan: 1, rowspan: 1 },
    ],
    // Row 6
    [
      { value: "SEALING FORMAT", colspan: 1, rowspan: 1 },
      {
        value: "BACK SEAL, THREE SIDE SEAL, FOUR SIDE SEAL",
        colspan: 3,
        rowspan: 1,
      },
    ],
  ],
};

export const VerticalPackingTableData7 = {
  headings: [
    "Model",
    "Voltage & Power",
    "Packing Range",
    "Film Width",
    "Bag Size",
    "Machine Size",
    "Weight",
    "Film Thickness",
  ],
  rows: [
    // Row 1
    [
      { value: "CK-LY350", colspan: 1, rowspan: 1 },
      { value: "1.8KW/220V", colspan: 1, rowspan: 1 },
      { value: "10-300ML", colspan: 1, rowspan: 1 },
      { value: "50-300MM", colspan: 1, rowspan: 1 },
      { value: "L:30-300MM W:20-140MM", colspan: 1, rowspan: 1 },
      { value: "780*920*1980MM", colspan: 1, rowspan: 1 },
      { value: "400KG", colspan: 1, rowspan: 1 },
      { value: "0.03MM-0.07MM", colspan: 1, rowspan: 1 },
    ],
    // Row 2
    [
      { value: "CK-LY500", colspan: 1, rowspan: 1 },
      { value: "1.8KW/220V", colspan: 1, rowspan: 1 },
      { value: "100-1000ML", colspan: 1, rowspan: 1 },
      { value: "180-420MM", colspan: 1, rowspan: 1 },
      { value: "L:80-300MM W:80-200MM", colspan: 1, rowspan: 1 },
      { value: "780*920*2100MM", colspan: 1, rowspan: 1 },
      { value: "450KG", colspan: 1, rowspan: 1 },
      { value: "0.03MM-0.07MM", colspan: 1, rowspan: 1 },
    ],
    // Row 3
    [
      { value: "CK-LY300", colspan: 1, rowspan: 1 },
      { value: "1.8KW/220V", colspan: 1, rowspan: 1 },
      { value: "10-120ML", colspan: 1, rowspan: 1 },
      { value: "50-280MM", colspan: 1, rowspan: 1 },
      { value: "L:40-150MM W:20-130MM", colspan: 1, rowspan: 1 },
      { value: "820*980*1680MM", colspan: 1, rowspan: 1 },
      { value: "400KG", colspan: 1, rowspan: 1 },
      { value: "0.04MM-0.08MM", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const PillowPackingTable1 = {
  headings: [
    { value: "MODEL" },
    { value: "FILMWIDTH" },
    { value: "BAGWIDTH" },
    { value: "PRODUCT HEIGHT" },
    { value: "FILM ROLL DIAMETER" },
    { value: "CAPACITY" },
    { value: "DIMENSION" },
    { value: "WEIGHT" },
    { value: "VOLTAGE / POWER" },
  ],
  rows: [
    [
      { value: "ZS220SE" },
      { value: "MAX220MM" },
      { value: "30-100MM" },
      { value: "5-35MM" },
      { value: "MAX300MM", rowspan: 9 },
      { value: "25-230 BAGS/MIN", rowspan: 4 },
      { value: "3890X910 X1390MM" },
      { value: "440KG" },
      { value: "220V/50/60HZ 4.1KW", rowspan: 2 },
    ],
    [
      { value: "ZS300SE" },
      { value: "MAX280MM" },
      { value: "35-140MM" },
      { value: "5-55MM" },
      { value: "3910X820 X1520MM" },
      { value: "520KG" },
    ],
    [
      { value: "ZS350SE" },
      { value: "MAX350MM" },
      { value: "50-160MM" },
      { value: "5-60MM" },
      { value: "3940X1010 X1390MM" },
      { value: "490KG" },
      { value: "220V/50/60HZ 8.7KW" },
    ],
    [
      { value: "ZS450SE" },
      { value: "MAX430MM" },
      { value: "50-180MM" },
      { value: "5-65MM" },
      { value: "4130X940 X1520MM" },
      { value: "600KG" },
      { value: "220V/50/60HZ 2.15KW" },
    ],
    [
      { value: "ZS600SE" },
      { value: "MAX580MM" },
      { value: "80-250MM" },
      { value: "5-80MM" },
      { value: "20-80 BAGS/MIN", rowspan: 2 },
      { value: "4040X1020 X1535MM" },
      { value: "890KG" },
      { value: "220V/50/60HZ 6.15KW", rowspan: 4 },
    ],
    [
      { value: "ZS700SE" },
      { value: "MAX700MM" },
      { value: "100-300MM" },
      { value: "5-100MM" },
      { value: "4130X1180 X1535MM" },
      { value: "1020KG" },
    ],
    [
      { value: "ZS450WSE" },
      { value: "MAX430MM" },
      { value: "50-160MM" },
      { value: "5-80MM" },
      { value: "20-80 BAGS/MIN", rowspan: 3 },
      { value: "3960X830 X1780MM" },
      { value: "930KG" },
    ],
    [
      { value: "ZS600WSE" },
      { value: "MAX580MM" },
      { value: "50-200MM" },
      { value: "5-100MM" },
      { value: "3960X930 X1780MM" },
      { value: "1010KG" },
    ],
    [
      { value: "ZS700WSE" },
      { value: "MAX700MM" },
      { value: "100-300MM" },
      { value: "5-100MM" },
      { value: "4390X1250 X1250MM" },
      { value: "960KG" },
      { value: "220V/50/60HZ 6.3KW" },
    ],
  ],
};

export const PillowPackingTable2 = {
  headings: [
    { value: "MODEL" },
    { value: "FILMWIDTH" },
    { value: "BAGWIDTH" },
    { value: "PRODUCT HEIGHT" },
    { value: "FILL ROLL DIAMETER" },
    { value: "CAPACITY" },
    { value: "DIMENSION" },
    { value: "WEIGHT" },
    { value: "VOLTAGE / POWER" },
  ],
  rows: [
    [
      { value: "ZS220XSE" },
      { value: "MAX220MM" },
      { value: "30-100MM" },
      { value: "5-35MM" },
      { value: "MAX300MM", rowspan: 5 },
      { value: "25-230 BAGS/MIN", rowspan: 3 },
      { value: "3940X880 X1520MM", rowspan: 2 },
      { value: "440KG" },
      { value: "220V/50/60HZ 3.9KW", rowspan: 2 },
    ],
    [
      { value: "ZS320XSE" },
      { value: "MAX320MM" },
      { value: "35-140MM" },
      { value: "5-55MM" },
      { value: "480KG" },
    ],
    [
      { value: "ZS450XSE" },
      { value: "MAX430MM" },
      { value: "50-160MM" },
      { value: "5-80MM" },
      { value: "3940X1070 X1520MM" },
      { value: "580KG" },
      { value: "220V/50/60HZ 5.95KW" },
    ],
    [
      { value: "ZS600XSE" },
      { value: "MAX580MM" },
      { value: "80-250MM" },
      { value: "5-100MM" },
      { value: "25-150 Bags/min" },
      { value: "4040x1020x1555mm" },
      { value: "760KG" },
      { value: "220V/50/60HZ 6.15KW" },
    ],
    [
      { value: "ZS220XSE" },
      { value: "MAX220MM" },
      { value: "30-100MM" },
      { value: "5-35MM" },
      { value: "25-80 BAGS/MIN" },
      { value: "4390x1250x1250mm" },
      { value: "960KG" },
      { value: "220V/50/60HZ 6.3KW" },
    ],
  ],
};

export const VacuumTableData1 = {
  headings: [
    "MODEL",
    "PACKING MATERIAL",
    "UPPER FILM WIDTH",
    "UNDER FILM WIDTH",
    "VOLTAGE",
    "POWER",
  ],
  rows: [
    [
      { value: "CK-LS420" },
      { value: "STRETCH FILM" },
      { value: "393MM" },
      { value: "422MM" },
      { value: "380VAC/50HZ" },
      { value: "7.8KW" },
    ],
    [
      { value: "CK-LS520" },
      { value: "STRETCH FILM" },
      { value: "493MM" },
      { value: "522MM" },
      { value: "380VAC/50HZ" },
      { value: "8.8KW" },
    ],
  ],
};

export const SpecialPackingTableData2 = {
  headings: ["MODEL", "CK-250"],
  rows: [
    [
      { value: "PACKAGING SIZE", colspan: 1, rowspan: 1 },
      { value: "WIDTH: 50-110MM LENGTH: 50-140MM", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "PACKAGING MATERIAL", colspan: 1, rowspan: 1 },
      {
        value:
          "COMPOSITE MATERIAL SUCH AS COMPOSITE FILMS, ALUMINIZED FILMS, POLYETHYLENE/POLYPROPYLENE",
        colspan: 1,
        rowspan: 1,
      },
    ],
    [
      { value: "WET WIPES MATERIAL", colspan: 1, rowspan: 1 },
      {
        value:
          "30-80G/M2 CLEAN PAPER, WET STRENGTH PAPER, NON-WOVEN FABRIC, SPUNLACE NON-WOVEN FABRIC",
        colspan: 1,
        rowspan: 1,
      },
    ],
    [
      { value: "UNFOLD THE SIZE OF THE WET WIPES", colspan: 1, rowspan: 1 },
      {
        value: "WIDTH: 30-240MM LENGTH: 60-240MM (ACCORDING TO REQUIREMENTS)",
        colspan: 1,
        rowspan: 1,
      },
    ],
    [
      { value: "FOLDING METHOD", colspan: 1, rowspan: 1 },
      {
        value:
          "FOLD UP TO 8 TIMES IN THE LONGITUDINAL DIRECTION AND 4 TIMES IN THE HORIZONTAL DIRECTION",
        colspan: 1,
        rowspan: 1,
      },
    ],
    [
      { value: "PACKAGING SPEED", colspan: 1, rowspan: 1 },
      { value: "60-120 BAGS/MIN", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "DOSING RANGE", colspan: 1, rowspan: 1 },
      { value: "0-5 ML LIQUID ACCURACY ± 0.01 ML", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "POWER", colspan: 1, rowspan: 1 },
      { value: "SINGLE PHASE 220V 50/60HZ 4.8KW", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "GAS CONSUMPTION", colspan: 1, rowspan: 1 },
      {
        value: "AIR COMPRESSOR 0.5-0.8 M3/MIN 300-500L/MIN",
        colspan: 1,
        rowspan: 1,
      },
    ],
    [
      { value: "MACHINE WEIGHT", colspan: 1, rowspan: 1 },
      { value: "1100KG", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "MACHINE SIZE", colspan: 1, rowspan: 1 },
      { value: "3560*1080*1900MM", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const SpecialPackingTableData3 = {
  headings: ["Quantity per bag", "5-30 pcs/pack"],
  rows: [
    [
      { value: "QUANTITY PER BAG", colspan: 1, rowspan: 1 },
      { value: "300-400PCS/MIN", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "PRODUCTION SPEED", colspan: 1, rowspan: 1 },
      {
        value: "AIR-LAID PAPER, SPUNLACE/HOT-ROLLING NON-WOVEN FABRIC",
        colspan: 1,
        rowspan: 1,
      },
    ],
    [
      { value: "SUITABLE RAW MATERIAL", colspan: 1, rowspan: 1 },
      { value: "40-80G/m2", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "WEIGHT OF RAW MATERIAL", colspan: 1, rowspan: 1 },
      { value: "(150~200)x(150-250)(MM,LxW)", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "WET TISSUE UNFOLDED SIZE", colspan: 1, rowspan: 1 },
      { value: "(75-100)x(45-80)(MM,LxW)", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "WET TISSUE FOLDED SIZE", colspan: 1, rowspan: 1 },
      { value: "Z AND DOUBLE Z", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "FOLDING WAY", colspan: 1, rowspan: 1 },
      {
        value: "AUTO-COUNTING SYSTEM, QUANTITY PER PILE CHANGEABLE SYSTEM",
        colspan: 1,
        rowspan: 1,
      },
    ],
    [
      { value: "COUNTING WAY", colspan: 1, rowspan: 1 },
      {
        value: "PACKING UNIT, LABELING UNIT, DATE PRINTING UNIT",
        colspan: 1,
        rowspan: 1,
      },
    ],
    [
      { value: "RELATED FUNCTION", colspan: 1, rowspan: 1 },
      { value: "800×800 (MM, DIA×H) 400L", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "WATER TANK SIZE", colspan: 1, rowspan: 1 },
      { value: "10.2KW", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "POWER", colspan: 1, rowspan: 1 },
      { value: "10.2kw", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const SpecialPackingTableData4 = {
  headings: ["Voltage", "380V, 50HZ"],
  rows: [
    [
      { value: "POWER", colspan: 1, rowspan: 1 },
      { value: "40KW", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "SUITABLE MATERIAL", colspan: 1, rowspan: 1 },
      { value: "SPUNLACE NON-WOVEN FABRIC", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "MATERIAL SPECIFICATION", colspan: 1, rowspan: 1 },
      { value: "35-80G/m2", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "WET WIPE UNFOLDING SIZE", colspan: 1, rowspan: 1 },
      { value: "(150-230)X(150-250)MM (LxW)", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "WET TISSUE FOLDED SIZE", colspan: 1, rowspan: 1 },
      { value: "(150-230)X(90-120)MM (LxW)", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "FINISHED PRODUCT SIZE", colspan: 1, rowspan: 1 },
      {
        value: "(200-280)X(90-120)X(30-100)MM (LxWxH)",
        colspan: 1,
        rowspan: 1,
      },
    ],
    [
      { value: "STABLE CAPACITY", colspan: 1, rowspan: 1 },
      { value: "4800-6400PCS/MIN", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "PACKING SPEED", colspan: 1, rowspan: 1 },
      { value: "60-80 BAGS/MIN", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "RAW PAPER SIZE", colspan: 1, rowspan: 1 },
      { value: "(800-1200)X1200MM (WxD)", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "FOLDING TYPE", colspan: 1, rowspan: 1 },
      { value: '"Z" (EXTRACTION OR NON-EXTRACTION)', colspan: 1, rowspan: 1 },
    ],
    [
      { value: "COUNTING MODE", colspan: 1, rowspan: 1 },
      { value: "AUTOMATIC COUNT", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "HUMIDIFICATION STIRRING SYSTEM", colspan: 1, rowspan: 1 },
      { value: "DOUBLE MIXING DRUM WITH MIXER", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "DIMENSION", colspan: 1, rowspan: 1 },
      { value: "19600X3500X2200MM (LxWxH)", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "TANK SIZE", colspan: 1, rowspan: 1 },
      { value: "2000X1100X1900MM (LxWxH)", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "WEIGHT", colspan: 1, rowspan: 1 },
      { value: "12000KG", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const SpecialPackingTableData6 = {
  headings: [
    { value: "PACKING WEIGHT", colspan: 1, rowspan: 1 },
    { value: "PACKING SPEED", colspan: 1, rowspan: 1 },
    { value: "SEALING METHODS", colspan: 1, rowspan: 1 },

    { value: "BAG SIZE (MM)", colspan: 2, rowspan: 1 },

    { value: "POWER", colspan: 1, rowspan: 1 },
    { value: "WEIGHT", colspan: 1, rowspan: 1 },
    { value: "DIMENSION", colspan: 1, rowspan: 1 },
  ],
  rows: [
    [
      { value: "3-10G (1.6~26ML)", colspan: 1, rowspan: 1 },
      { value: "30-40 BAGS/MIN", colspan: 1, rowspan: 1 },
      { value: "3-SIDE SEALING METHODS", colspan: 1, rowspan: 1 },

      { value: "INNER BAG LENGTH 50-80 WIDTH 45-80", colspan: 1, rowspan: 1 },
      {
        value: "Outer bag Length 80 - 120 Width 75 - 95",
        colspan: 1,
        rowspan: 1,
      },

      { value: "220V/50HZ / 3.7KW", colspan: 1, rowspan: 1 },
      { value: "500KG", colspan: 1, rowspan: 1 },
      { value: "1750*740*1950 (L*W*H)MM", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const SpecialPackingTableData7 = {
  headings: [
    { value: "PACKING WEIGHT", colspan: 1, rowspan: 2 },
    { value: "PACKING SPEED", colspan: 1, rowspan: 2 },
    { value: "SEALING METHODS", colspan: 1, rowspan: 2 },
    { value: "BAG SIZE (MM)", colspan: 1, rowspan: 2 },
    { value: "POWER", colspan: 1, rowspan: 2 },
    { value: "WEIGHT", colspan: 1, rowspan: 2 },
    { value: "DIMENSION", colspan: 1, rowspan: 2 },
  ],
  rows: [
    [
      { value: "2-3G (4~10ML)", colspan: 1, rowspan: 1 },
      { value: "25-35 BAGS/MIN", colspan: 1, rowspan: 1 },
      {
        value: "HEAT-SEALING MOLD COMPRESSION MOLDING",
        colspan: 1,
        rowspan: 1,
      },
      { value: "70 MM ROUND FILTER PAPER PACKAGING", colspan: 1, rowspan: 1 },
      { value: "220V/50HZ / 3.5KW", colspan: 1, rowspan: 1 },
      { value: "400 KG", colspan: 1, rowspan: 1 },
      { value: "1500*1210*2120 (L*W*H) MM", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const SpecialPackingTableData9 = {
  headings: [
    { value: "PACKING MODEL", colspan: 1, rowspan: 2 },
    { value: "CAPACITY", colspan: 1, rowspan: 2 },
    { value: "BAG SIZE", colspan: 1, rowspan: 2 },
    { value: "FILM", colspan: 2, rowspan: 1 },
    { value: "PACKING MATERIAL", colspan: 1, rowspan: 2 },
    { value: "TOTAL POWER", colspan: 1, rowspan: 2 },
    { value: "DIMENSION", colspan: 1, rowspan: 2 },
  ],
  rows: [
    [
      { value: "CKSD-90", colspan: 1, rowspan: 1 },
      { value: "30-60 BAGS/MIN", colspan: 1, rowspan: 1 },
      { value: "60-80 (W)* 40-80 (L)", colspan: 1, rowspan: 1 },
      { value: "2-10G", colspan: 1, rowspan: 1 },
      { value: "0.04-0.06 MM", colspan: 1, rowspan: 1 },
      {
        value:
          "Nylon,Polyester netnon - woven fabrics, ultrasonic sealing materials",
        colspan: 1,
        rowspan: 1,
      },
      { value: "2.2KW 220V/50HZ/ SINGLE PHASE", colspan: 1, rowspan: 1 },
      { value: "920*840*2050", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "CK-60QD", colspan: 1, rowspan: 1 },
      { value: "20-35 BAGS/MIN", colspan: 1, rowspan: 1 },
      { value: "/", colspan: 1, rowspan: 1 },
      { value: "/", colspan: 1, rowspan: 1 },
      { value: "/", colspan: 1, rowspan: 1 },
      { value: "/", colspan: 1, rowspan: 1 },
      { value: "1.5 KW 220V, 50HZ, 1PHASE", colspan: 1, rowspan: 1 },
      { value: "1080*1255 *1880", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const LiquidPasteFillingTableData = {
  headings: [
    { value: "Model", colspan: 1, rowspan: 1 },
    { value: "YGZ-2G", colspan: 1, rowspan: 1 },
    { value: "YGZ-4G", colspan: 1, rowspan: 1 },
    { value: "YGZ-6G", colspan: 1, rowspan: 1 },
    { value: "YGZ-8G", colspan: 1, rowspan: 1 },
    { value: "YGZ-10G", colspan: 1, rowspan: 1 },
    { value: "YGZ-15G", colspan: 1, rowspan: 1 },
  ],
  rows: [
    [
      { value: "FILLINGSPEED", colspan: 1, rowspan: 1 },
      { value: "4-20BOTTLE/MIN", colspan: 1, rowspan: 1 },
      { value: "8-40BOTTLE/MIN", colspan: 1, rowspan: 1 },
      { value: "12-60BOTTLE/MIN", colspan: 1, rowspan: 1 },
      { value: "16-80BOTTLE/MIN", colspan: 1, rowspan: 1 },
      { value: "20-1000BOTTLE/MIN", colspan: 1, rowspan: 1 },
      { value: "24-120BOTTLE/MIN", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "FILLING ACCURACY", colspan: 1, rowspan: 1 },
      { value: "±1%", colspan: 6, rowspan: 1 },
    ],
    [
      { value: "VOLTAGE", colspan: 1, rowspan: 1 },
      { value: "220V 50HZ", colspan: 6, rowspan: 1 },
    ],
    [
      { value: "AIR PRESSURE", colspan: 1, rowspan: 1 },
      { value: "0.6-0.8MPA", colspan: 6, rowspan: 1 },
    ],
    [
      { value: "POWER", colspan: 1, rowspan: 1 },
      { value: "1000W", colspan: 1, rowspan: 1 },
      { value: "1000W", colspan: 1, rowspan: 1 },
      { value: "1500W", colspan: 1, rowspan: 1 },
      { value: "1500W", colspan: 1, rowspan: 1 },
      { value: "2000W", colspan: 1, rowspan: 1 },
      { value: "2000W", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "FILLING HEAD", colspan: 1, rowspan: 1 },
      { value: "2 HEADS", colspan: 1, rowspan: 1 },
      { value: "4 HEADS", colspan: 1, rowspan: 1 },
      { value: "6 HEADS", colspan: 1, rowspan: 1 },
      { value: "8 HEADS", colspan: 1, rowspan: 1 },
      { value: "10 HEADS", colspan: 1, rowspan: 1 },
      { value: "12 HEADS", colspan: 1, rowspan: 1 },
    ],
    [
      { value: "FILLING RANGE", colspan: 1, rowspan: 1 },
      {
        value:
          "20-100ml, 30-300ml, 50-500ml, 100-1000ml, 500-2000ml, 700-3000ml, 1000-5000ml",
        colspan: 6,
        rowspan: 1,
      },
    ],
    [
      { value: "DIMENSION", colspan: 1, rowspan: 1 },
      { value: "2000*800*1800MM", colspan: 1, rowspan: 1 },
      { value: "2000*800*1800MM", colspan: 1, rowspan: 1 },
      { value: "3000*1000*1800MM", colspan: 1, rowspan: 1 },
      { value: "3000*1000*1800MM", colspan: 1, rowspan: 1 },
      { value: "4000*1100*2000MM", colspan: 1, rowspan: 1 },
      { value: "4000*1100*2000MM", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const OverWrappingTableData1 = {
  headings: [
    "Model",
    "CK-BTB-300A",
    "CK-BTB-300B",
    "CK-BTB-300D",
    "CK-BTB-300S",
    "CK-BTB-400",
  ],
  rows: [
    // Row 1
    [
      { value: "PACKAGING MATERIAL", colspan: 1, rowspan: 1 },
      { value: "FILM & GOLD TEAR TAPE", colspan: 1, rowspan: 1 },
      { value: "FILM & GOLD TEAR TAPE", colspan: 1, rowspan: 1 },
      { value: "FILM & GOLD TEAR TAPE", colspan: 1, rowspan: 1 },
      { value: "FILM & GOLD TEAR TAPE", colspan: 1, rowspan: 1 },
      { value: "FILM & GOLD TEAR TAPE", colspan: 1, rowspan: 1 },
    ],
    // Row 2
    [
      { value: "PACKING SPEED", colspan: 1, rowspan: 1 },
      { value: "40-80 BOXES/MIN", colspan: 1, rowspan: 1 },
      { value: "40-80 BOXES/MIN", colspan: 1, rowspan: 1 },
      { value: "40-80 BOXES/MIN", colspan: 1, rowspan: 1 },
      { value: "10-25 BOXES/MIN", colspan: 1, rowspan: 1 },
      { value: "40-80 BOXES/MIN", colspan: 1, rowspan: 1 },
    ],
    // Row 3
    [
      { value: "MAX PACKAGE SIZE", colspan: 1, rowspan: 1 },
      { value: "(L)240*(W)120*(H)60MM", colspan: 1, rowspan: 1 },
      { value: "(L)240*(W)120*(H)60MM", colspan: 1, rowspan: 1 },
      { value: "(L)240*(W)120*(H)60MM", colspan: 1, rowspan: 1 },
      { value: "(L)240*(W)120*(H)60MM", colspan: 1, rowspan: 1 },
      { value: "(L)300*(W)200*(H)100MM", colspan: 1, rowspan: 1 },
    ],
    // Row 4
    [
      { value: "POWER", colspan: 1, rowspan: 1 },
      { value: "220V 50HZ 5KW", colspan: 1, rowspan: 1 },
      { value: "220V 50HZ 5KW", colspan: 1, rowspan: 1 },
      { value: "220V 50HZ 5KW", colspan: 1, rowspan: 1 },
      { value: "220V 50HZ 5KW", colspan: 1, rowspan: 1 },
      { value: "220V 50HZ 5KW", colspan: 1, rowspan: 1 },
    ],
    // Row 5
    [
      { value: "MACHINE WEIGHT", colspan: 1, rowspan: 1 },
      { value: "500KG", colspan: 1, rowspan: 1 },
      { value: "600KG", colspan: 1, rowspan: 1 },
      { value: "550KG", colspan: 1, rowspan: 1 },
      { value: "760KG", colspan: 1, rowspan: 1 },
      { value: "1250KG", colspan: 1, rowspan: 1 },
    ],
    // Row 6
    [
      { value: "MACHINE DIMENSIONS", colspan: 1, rowspan: 1 },
      { value: "(L)2000*(W)700*(H)1400", colspan: 1, rowspan: 1 },
      { value: "(L)3000*(W)800*(H)1500", colspan: 1, rowspan: 1 },
      { value: "(L)2000*(W)700*(H)1400", colspan: 1, rowspan: 1 },
      { value: "(L)2001*(W)923*(H)1779", colspan: 1, rowspan: 1 },
      { value: "(L)2380*(W)1050*(H)1680", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const OverWrappingTableData2 = {
  headings: [
    { value: "MODEL" },
    { value: "POWER (KW)" },
    { value: "VOLTAGE" },
    { value: "MAX PACKING SIZE (L*W MM)" },
    { value: "MAX SEALING SIZE (MM)" },
    { value: "PACKING SPEED (BAG/MIN)" },
    { value: "MACHINE SIZE (MM)" },
    { value: "WEIGHT (KG)" },
    { value: "GAS SOURCE" },
    { value: "SHRINK FILM" },
  ],
  rows: [
    [
      { value: "CK-FL450" },
      { value: "1.5" },
      { value: "AC220V\n50/60HZ" }, // Line break in VOLTAGE
      { value: "550*450" },
      { value: "<500" },
      { value: "15-30", colspan: 1, rowspan: 3 },
      { value: "6-8Kg/cm^2", colspan: 1, rowspan: 3 },
      { value: "POF/PE" },
      { value: "1650*880*1450" },
      { value: "280" },
    ],
    [
      { value: "CK-FL550" },
      { value: "2.2" },
      { value: "AC220V\n50/60HZ" }, // Line break in VOLTAGE
      { value: "650*550" },
      { value: "<600" },
      { value: "POF/PE" },
      { value: "1750*900*1450" },
      { value: "350" },
    ],
    [
      { value: "CK-FL750" },
      { value: "3.5" },
      { value: "AC220V\n50/60HZ" }, // Line break in VOLTAGE
      { value: "850*750" },
      { value: "<800" },
      { value: "POF/PE" },
      { value: "1950*1200*1550" },
      { value: "550" },
    ],
  ],
};


export const OverWrappingTableData3 = {
  headings: [
    "MODEL",
    "POWER SUPPLY",
    "CAPACITY",
    "SEALING TIME / SHRINK TIME",
    "CONVEYOR LOADING",
    "FILM THICKNESS",
    "MAX PACKING DIMENSION",
    "MIN PACKING DIMENSION",
    "MACHINE SIZE (L*W*H)",
    "WEIGHT",
    "SHRINK FILM",
  ],
  rows: [
    [
      { value: "CK-BZJ-5038B", colspan: 1, rowspan: 1 },
      { value: "220-240V, 50-60HZ: 1.8KW", colspan: 1, rowspan: 1 },
      { value: "0-6 PACKS/MIN", colspan: 1, rowspan: 1 },
      { value: "0.5-1.5S  0-0.65S", colspan: 1, rowspan: 1 },
      { value: "CONVEYOR LOADING: 30KG", colspan: 1, rowspan: 1 },
      { value: "0.03-0.25MM", colspan: 1, rowspan: 1 },
      { value: "MAX: 450 X 270 X 350MM", colspan: 1, rowspan: 1 },
      { value: "MIN: 250 X 60 X 60MM", colspan: 1, rowspan: 1 },
      { value: "1020 X 830 X 1980MM", colspan: 1, rowspan: 1 },
      { value: "225KGS", colspan: 1, rowspan: 1 },
      { value: "PE", colspan: 1, rowspan: 1 },
    ],
  ],
};

export const categories = {
  "Packing Solution": {
    id: 1,
    navigation: "PackingSolution",
    heading: "Packing Solution",
    paragraph:
      "The Packing Solution category includes versatile, high-speed machines for efficient and automated packaging of various materials such as liquids, powders, and particles. These machines feature precise counting, flexible packing, minimal manual intervention, and durable construction, ensuring reliable and contamination-free operation across industries like food, pharmaceuticals, and chemicals.",
    images: [
      require("../assets/Dynamic-Cards/PackingSolution/1.png"),
      require("../assets/Dynamic-Cards/PackingSolution/2.png"),
      require("../assets/Dynamic-Cards/PackingSolution/3.png"),
      require("../assets/Dynamic-Cards/PackingSolution/4.png"),
    ],
  },
  "Vertical Packing": {
    id: 2,
    heading: "Vertical Packing",
    navigation: "VerticalPacking",
    paragraph:
      "The Vertical Packing category features versatile machines for efficient packaging of granules, powders, liquids, and pastes. These machines offer precise control, high accuracy, automatic detection, and minimal manual intervention, ensuring high-quality sealing and low material loss. Key models include the Preformed Pouch Filling Sealing Machine, Multi-head Weighing and Packing Machine, and various sachet packing machines.",
    images: [
      require("../assets/Dynamic-Cards/VerticalPacking/Rectangle 63.png"),
      require("../assets/Dynamic-Cards/VerticalPacking/Rectangle 65.png"),
      require("../assets/Dynamic-Cards/VerticalPacking/Rectangle 67.png"),
      require("../assets/Dynamic-Cards/VerticalPacking/Rectangle 68.png"),
    ],
  },
  "Liquid & Paste Filling": {
    id: 3,
    navigation: "PackingSolution",
    heading: "Liquid & Paste Filling",
    paragraph: "This series of liquid filling machines suits industries like daily chemical, food, oil, medicine, and pesticides, filling pastes, semi-fluid products, sauces, honey, and jams with high precision and no dripping. The cylinder-controlled piston ensures easy installation and maintenance, and features include anti-drip, adjustable filling speed, and heated, insulated contact parts, with options for a touch screen and protective cover.",
    images: [
      require('../assets/Dynamic-Cards/Liquid&PasteFilling/Rectangle 63.png'),
      require('../assets/Dynamic-Cards/Liquid&PasteFilling/Rectangle 65.png'),
      require('../assets/Dynamic-Cards/Liquid&PasteFilling/Rectangle 67.png'),
      require('../assets/Dynamic-Cards/Liquid&PasteFilling/Rectangle 68.png'),
    ],
  },
  "Pillow Packing": {
    id: 4,
    navigation: "PillowPacking",
    heading: "Pillow Packing",
    paragraph: "The Pillow Packing Machine category, including Up Film and Down Film models, is ideal for packing goods like pharmaceuticals, toys, and spare parts. Both models offer precise bag length control, quick parameter setting via a touch screen, fault self-diagnosis, accurate sealing and cutting, and PID temperature control. Software-based controls allow easy adjustments and upgrades.",
    images: [
      require('../assets/Dynamic-Cards/PillowPacking/Rectangle 63.png'),
      require('../assets/Dynamic-Cards/PillowPacking/Rectangle 65.png'),
      require('../assets/Dynamic-Cards/PillowPacking/Rectangle 67.png'),
      require('../assets/Dynamic-Cards/PillowPacking/Rectangle 68.png'),
    ],
  },
  "Over Wrapping": {
    id: 5,
    navigation: "OverWrapping",
    heading: "Over Wrapping",
    paragraph: "Overwrapping machines, including Cellophane Wrapping, L Bar Shrink Wrap, and Sleeve Shrink Wrapping models, used for packaging items like medicines, cosmetics, and food. They offer features like automatic feeding and sealing, high packing efficiency, easy adjustments for different sizes, and safety systems to protect the operator. The machines ensure stable operation, high-quality sealing, and compatibility with other products.",
    images: [
      require('../assets/Dynamic-Cards/OverWrapping/Rectangle 63.png'),
      require('../assets/Dynamic-Cards/OverWrapping/Rectangle 65.png'),
      require('../assets/Dynamic-Cards/OverWrapping/Rectangle 67.png'),
      require('../assets/Dynamic-Cards/OverWrapping/Rectangle 68.png'),
    ],
  },
  "Vacuum Packing": {
    id: 6,
    navigation: "VacuumPacking",
    heading: "Vacuum Packing",
    paragraph: "The Stretch Film Vacuum Packing Machine is suitable for vacuum packaging frozen meats, snacks, fruits, vegetables, and medical equipment. It has a high-strength alloy aluminum frame for stability, high vacuum efficiency, low noise, and reliable performance. Controlled by a PLC system with a servo motor, it ensures precise operations and allows for quick mold replacement to meet various needs.",
    images: [
      require('../assets/Dynamic-Cards/VaccumPacking/Rectangle 63.png'),
      require('../assets/Dynamic-Cards/VaccumPacking/Rectangle 65.png'),
      require('../assets/Dynamic-Cards/VaccumPacking/Rectangle 67.png'),
      require('../assets/Dynamic-Cards/VaccumPacking/Rectangle 68.png'),
    ],
  },
  "Special Purpose": {
    id: 7,
    navigation: "SpecialPacking",
    heading: "Special Purpose",
    paragraph: "These machines handle unique packaging tasks: Wet Wipe Machines provide durable construction and precise liquid dispensing; Multi-piece Wet Wipe Machines automate stacking and labeling; Tea Bag Machines offer precise control and versatile bag shapes; and Pyramid Tea Bag Machines use ultrasonic sealing for accurate, flexible packaging. They are designed for high efficiency, durability, and adaptability.",
    images: [
      require('../assets/Dynamic-Cards/SpecialPurpose/Rectangle 63.png'),
      require('../assets/Dynamic-Cards/SpecialPurpose/Rectangle 65.png'),
      require('../assets/Dynamic-Cards/SpecialPurpose/Rectangle 67.png'),
      require('../assets/Dynamic-Cards/SpecialPurpose/Rectangle 68.png'),
    ],
  },
};
