import React from "react";
import { useMediaQuery } from 'react-responsive';


const HomeSectionsHeading = ({ height = 'auto', title, heading, backgroundColor = "#EF3C0F", mainContainerStyle }) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 360px)' });
  const isMediumScreen = useMediaQuery({ query: '(max-width: 768px)' });


  const lineStyle = {
    height:height-20 ,
   padding:isSmallScreen? '2px': isMediumScreen? '2.5px' :'3.5px',
   backgroundColor:backgroundColor,
   marginTop:3,
   marginBottom: 7,
    marginRight: isSmallScreen? 8: isMediumScreen?10 : 15 
  }
  const divStyle = {
    // fontSize: isSmallScreen ? '0.8rem' : isMediumScreen ? '0.9rem' : '1rem',
    lineHeight: isSmallScreen ? '0.5' : isMediumScreen ? '1.2' : '1.4',
    };
  
  return (
    <div className="d-flex justify-content-start home_section_heading" style={mainContainerStyle}>
      <div style={lineStyle}></div>
      <div style={divStyle}> {/* Adjust lineHeight to control vertical spacing */}
        <h1 className="lato-bold">{title}</h1>
        <h2 className="heading-content lato-black" dangerouslySetInnerHTML={{ __html: heading }}/>
      </div>
    </div>
  );
};

export default HomeSectionsHeading;
