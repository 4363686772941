import React, { useState } from "react";
import TopMovingSlider from "./TopMovingSlider";
import { IoLogoWhatsapp } from "react-icons/io";
import { Image } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  FaAngleDown,
  FaAngleRight,
  FaFacebookF,
  FaInstagram,
} from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { GiRotaryPhone } from "react-icons/gi";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { AiFillInstagram } from "react-icons/ai";
import { RxHamburgerMenu } from "react-icons/rx";
import MobileHeader from "./MobileHeader";

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  return (
    <div className="p-0 m-0">
      <TopMovingSlider />
      <div className="main-header-container">
        <div className="d-flex justify-content-between layout-padding">
          <div className="d-flex social-icon-container">
            <a href="https://www.whatsapp.com" target="_blank">
              <div className="header-social-box">
                <IoLogoWhatsapp className="social-icon" />
              </div>
            </a>
            <a href="https://www.facebook.com" target="_blank">
              <div className="header-social-box">
                <FaFacebookF className="social-icon" />
              </div>
            </a>

            <a href="https://www.instagram.com/" target="_blank">
              <div className="header-social-box">
                <AiFillInstagram className="social-icon" />
              </div>
            </a>
          </div>
          <div className="logo-container">
            <Image
              src={require("../assets/images/Revo-Logo.png")}
              alt="logo"
              className="main-logo"
              onClick={() => navigate("/")}
            />
          </div>
          <div className="d-flex social-icon-container">
            <a href="https://www.google.com/maps" target="_blank">
              <div className="header-social-box">
                <FaLocationDot className="social-icon" />
              </div>
            </a>

            <a href="https://mail.google.com" target="_blank">
              <div className="header-social-box">
                <MdEmail className="social-icon" />
              </div>
            </a>

            <a href="" target="_blank">
              <div className="header-social-box">
                <GiRotaryPhone className="social-icon" />
              </div>
            </a>
          </div>
        </div>
        <div className="divider" />
        <header className={`main-header`}>
          <div className="d-flex align-items-center justify-content-center m-0 p-0 nav-container">
            <ul className="nav-menu d-flex p-0 m-0 word-spacing header-letter-spacing">
              <li className={location.pathname === "/" ? "active" : ""}>
                <NavLink to="/">Home</NavLink>
              </li>
              <li
                className={
                  location.pathname === "/PackingSolution" ? "active" : ""
                }
              >
                <NavLink to="/PackingSolution">Packing Solution</NavLink>
                <div className="_dropdown-menu">
                  <div className="container p-2">
                    <div className="row">
                      <div className="col">
                        <ul>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate(
                                "/PackingSolution#multi-lane-counting-machine"
                              )
                            }
                            to="multi-lane-counting-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> High Speed
                              Multi-Lane Counting And Packing Solution
                            </li>
                          </Link>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate("/PackingSolution#mix-grain-machine")
                            }
                            to="mix-grain-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Mixed
                              Grain Nuts Packing Solution
                            </li>
                          </Link>
                        </ul>
                      </div>
                      <div className="col">
                        <ul>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate("/PackingSolution#feeding-machine")
                            }
                            to="feeding-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Automatic
                              Feeding & Packing Solution
                            </li>
                          </Link>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate(
                                "/PackingSolution#juice-filling-solution"
                              )
                            }
                            to="juice-filling-solution"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Juice
                              Filling Solution
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className={
                  location.pathname === "/VerticalPacking" ? "active" : ""
                }
              >
                <NavLink to="/VerticalPacking">Vertical Packing</NavLink>
                <div className="_dropdown-menu">
                  <div className="container p-2">
                    <div className="row">
                      <div className="col">
                        <ul>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate("/VerticalPacking#pre-made-bag")
                            }
                            to="pre-made-bag"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Premade
                              Bag Packing Machine
                            </li>
                          </Link>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate(
                                "/VerticalPacking#multi-head-weighing-machine"
                              )
                            }
                            to="multi-head-weighing-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Multi-Head
                              Weighing And Packing Machine
                            </li>
                          </Link>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate(
                                "/VerticalPacking#powder-packing-machine"
                              )
                            }
                            to="powder-packing-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Powder
                              Packing Machine
                            </li>
                          </Link>
                        </ul>
                      </div>
                      <div className="col">
                        <ul>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate("/VerticalPacking#powder-sachet-machine")
                            }
                            to="powder-sachet-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Powder
                              Sachet Packing Machine
                            </li>
                          </Link>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate(
                                "/VerticalPacking#automatic-feeding-machine"
                              )
                            }
                            to="automatic-feeding-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Automatic
                              Feeding & Packing Solution
                            </li>
                          </Link>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate("/VerticalPacking#juice-filling-machine")
                            }
                            to="juice-filling-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Juice
                              Filling Solution
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className={
                  location.pathname === "/LiquidAndPasteFilling" ? "active" : ""
                }
              >
                <NavLink to="/LiquidAndPasteFilling">
                  Liquid & Paste Filling
                </NavLink>
                <div className="_dropdown-menu">
                  <div className="container p-2">
                    <div className="row">
                      <div className="col">
                        <ul>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate(
                                "/LiquidAndPasteFilling#paste-filling-machine"
                              )
                            }
                            to="paste-filling-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Automatic
                              Liquid & Paste Filling Machine
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className={
                  location.pathname === "/PillowPacking" ? "active" : ""
                }
              >
                <NavLink to="/PillowPacking">Pillow Packing</NavLink>
                <div className="_dropdown-menu">
                  <div className="container p-2">
                    <div className="row">
                      <div className="col">
                        <ul>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate("/PillowPacking#up-fil-machine")
                            }
                            to="up-fil-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Up Film
                              Pillow Packing Machine
                            </li>
                          </Link>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate("/PillowPacking#bottom-filling-machine")
                            }
                            to="bottom-filling-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Bottom
                              Film Pillow Packing Machine
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className={
                  location.pathname === "/OverWrapping" ? "active" : ""
                }
              >
                <NavLink to="/OverWrapping">Overwrapping</NavLink>
                <div className="_dropdown-menu">
                  <div className="container p-2">
                    <div className="row">
                      <div className="col">
                        <ul>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate("/OverWrapping#cellophane-machine")
                            }
                            to="cellophane-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Cellophane
                              Wrapping Machine
                            </li>
                          </Link>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate("/OverWrapping#lbar-machine")
                            }
                            to="lbar-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> L Bar
                              Shrink Wrap Machine
                            </li>
                          </Link>
                        </ul>
                      </div>
                      <div className="col">
                        <ul>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate("/OverWrapping#sleeve-shrink-machine")
                            }
                            to="sleeve-shrink-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Sleeve
                              Shrink Wrapping Machine
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className={
                  location.pathname === "/VacuumPacking" ? "active" : ""
                }
              >
                <NavLink to="/VacuumPacking">Vacuum Packing</NavLink>
                <div className="_dropdown-menu">
                  <div className="container p-2">
                    <div className="row">
                      <div className="col">
                        <ul>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate("/VacuumPacking#stretch-film-machine")
                            }
                            to="stretch-film-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Stretch
                              Film Packing Machine
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className={
                  location.pathname === "/SpecialPacking" ? "active" : ""
                }
              >
                <NavLink to="/SpecialPacking">Special Purpose</NavLink>
                <div className="_dropdown-menu">
                  <div className="container p-2">
                    <div className="row">
                      <div className="col">
                        <ul>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate("/SpecialPacking#wet-wipe-machine")
                            }
                            to="wet-wipe-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Wet Wipe
                              Packing Machine
                            </li>
                          </Link>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate(
                                "/SpecialPacking#multi-piece-wet-wipes-5-30pcs"
                              )
                            }
                            to="multi-piece-wet-wipes-5-30pcs"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" />{" "}
                              Multi-piece Wet Wipes Packing Machine 5~30PCS
                            </li>
                          </Link>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate(
                                "/SpecialPacking#multi-piece-wet-wipes-30-120pcs"
                              )
                            }
                            to="multi-piece-wet-wipes-30-120pcs"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" />{" "}
                              Multi-piece Wet Wipes Packing Machine 30~120pcs
                            </li>
                          </Link>
                        </ul>
                      </div>
                      <div className="col">
                        <ul>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate(
                                "/SpecialPacking#automatic-tea-bag-machine"
                              )
                            }
                            to="automatic-tea-bag-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Automatic
                              Tea Bag Machine With Inner and Outer Envelope
                            </li>
                          </Link>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate(
                                "/SpecialPacking#tea-bag-packing-machine"
                              )
                            }
                            to="tea-bag-packing-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Tea Bag
                              Packing Machine
                            </li>
                          </Link>
                          <Link
                            className="m-0 b-0"
                            onClick={() =>
                              navigate(
                                "/SpecialPacking#pyramid-tea-bag-machine"
                              )
                            }
                            to="pyramid-tea-bag-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Pyramid
                              Tea Bag Packaging Machine
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className={
                  location.pathname === "/PowderProcessing" ? "active" : ""
                }
              >
                <NavLink to="/PowderProcessing">POWDER PROCESSING</NavLink>
              </li>
              <li
                className={
                  location.pathname === "/AboutUs" ||
                  location.pathname === "/ContactUs"
                    ? "active"
                    : ""
                }
              >
                <NavLink
                  className={
                    location.pathname === "/AboutUs" ||
                    location.pathname === "/ContactUs"
                      ? "active"
                      : ""
                  }
                  to="/ContactUs"
                >
                  QUICK LINKS
                </NavLink>
                <div className="_dropdown-menu">
                  <div className="container p-2">
                    <div className="row">
                      <div className="col">
                        <ul>
                          <Link
                            className="m-0 b-0"
                            onClick={() => navigate("/AboutUs")}
                            to="wet-wipe-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> About Us
                            </li>
                          </Link>
                        </ul>
                      </div>
                      <div className="col">
                        <ul>
                          <Link
                            className="m-0 b-0"
                            onClick={() => navigate("/ContactUs")}
                            to="automatic-tea-bag-machine"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <li>
                              <FaAngleRight className="menu-angle" /> Contact Us
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </header>
      </div>
      <MobileHeader />
    </div>
  );
}

export default Header;
