import React from 'react'

const HomePageBtn = ({ btnText, style, onClick }) => {
  return (
    <div className='home_page_btn' style={style} onClick={onClick}>
      <a>
        {btnText}
      </a>
    </div>
  )
}

export default HomePageBtn