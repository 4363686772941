import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

const AccordionComp = ({ data }) => {
    return (
        <Accordion defaultActiveKey="0" alwaysOpen className='accordion-container'>
            {data.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index} className="custom-accordion-item">
                    <Accordion.Header>
                        <h4 className="custom-accordion-header">
                            {item.title}
                        </h4>
                    </Accordion.Header>
                    <Accordion.Body className="custom-accordion-body">
                        {item.content}
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

export default AccordionComp;
