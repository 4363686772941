import React from 'react'
import HomePageBtn from './HomePageBtn'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';

function Divider_comp({ isLine = false }) {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 360px)' });
  const isMediumScreen = useMediaQuery({ query: '(max-width: 768px)' });
//   const isLargeScreen = useMediaQuery({ query: '(max-width: 1200px)' });


  const buttonStyles = {
  
    marginLeft: isSmallScreen ? '10px' : isMediumScreen ? '10px' : '60px',
    marginRight: isSmallScreen ? '0px' : isMediumScreen ? '0px' : '0px',

  };

    const navigate = useNavigate()
    return (
        <div className=' d-flex justify-content-start align-items-center  justify-content-md-between  divider-comp'  >
            {!isLine &&
                <HomePageBtn btnText={"Contact us For Consultation"}  style={buttonStyles} onClick={() => navigate('/ContactUs')}/>
            }
            <div className='custom-horizontal-line' />
        </div>
    )
}

export default Divider_comp