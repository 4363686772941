import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { FaAngleRight, FaChevronUp, FaEnvelope } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleNavigation = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  return (
    <footer className="footer">
      <div className="container fotter-container">
        <div className="row row-col">
          <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
            <Image
              src={require('../assets/images/Revo-Footer-Logo.png')}
              alt="Revolutionary Machines Logo"
              className="footer-logo"
              onClick={() => navigate('/')}
            />
            <p className="footer-email">
              <FaEnvelope className="footer-icon" /> Email: info@revolutionarymachines.com
            </p>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <div
              className="d-flex align-items-center justify-content-between"
              onClick={() => toggleDropdown('quickLinks')}
            >
              <h5>QUICK LINKS</h5>
              <FaAngleRight
                className={`footer-angle ${openDropdown === 'quickLinks' ? 'rotate' : ''}`}
              />
            </div>
            <ul className={`list-unstyled ${openDropdown === 'quickLinks' ? 'show' : 'hide'}`}>
              <li onClick={() => handleNavigation("/")}>Home</li>
              <li onClick={() => navigate('/#all_category')}>Categories</li>
              <li onClick={() => handleNavigation("/AboutUs")}>About Us</li>
              <li onClick={() => handleNavigation("/ContactUs")}>Contact Us</li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <div
              className="d-flex align-items-center justify-content-between"
              onClick={() => toggleDropdown('allCategories')}
            >
              <h5>ALL CATEGORIES</h5>
              <FaAngleRight
                className={`footer-angle ${openDropdown === 'allCategories' ? 'rotate' : ''}`}
              />
            </div>
            <ul className={`list-unstyled ${openDropdown === 'allCategories' ? 'show' : 'hide'}`}>
              <li onClick={() => handleNavigation("/PackingSolution")}>Packing Solution</li>
              <li onClick={() => handleNavigation("/VerticalPacking")}>Vertical Packing</li>
              <li onClick={() => handleNavigation("/LiquidAndPasteFilling")}>Liquid and Paste Filling</li>
              <li onClick={() => handleNavigation("/PillowPacking")}>Pillow Packing</li>
              <li onClick={() => handleNavigation("/VacuumPacking")}>Vacuum Packing</li>
              <li onClick={() => handleNavigation("/SpecialPacking")}>Special Purpose</li>
            </ul>
          </div>

          <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
            <div
              className="d-flex align-items-center justify-content-between"
              onClick={() => toggleDropdown('ourPolicies')}
            >
              <h5>OUR POLICIES</h5>
              <FaAngleRight
                className={`footer-angle ${openDropdown === 'ourPolicies' ? 'rotate' : ''}`}
              />
            </div>
            <ul className={`list-unstyled ${openDropdown === 'ourPolicies' ? 'show' : 'hide'}`}>
              <li onClick={() => handleNavigation("/PaymentPolicy")}>Payment Policy</li>
              <li onClick={() => handleNavigation("/RefundPolicy")}>Refund Policy</li>
              <li onClick={() => handleNavigation("/DeliveryPolicy")}>Delivery Policy</li>
              <li onClick={() => handleNavigation("/TermsOfServices")}>Terms of Services</li>
            </ul>
          </div>
        </div>
      </div>

      <a href="#" className="back-to-top">
        <FaChevronUp />
      </a>
      <div className="text-center copyright">
        <span>&copy; Copyright Revolutionary Machines. All Rights Reserved</span>
      </div>
    </footer>
  );
}

export default Footer;
