import logo from './logo.svg';
import './App.css';
import './Responsive.css';
import MainRoute from './routes/Route';

function App() {
  return (
    <MainRoute />
  );
}

export default App;
