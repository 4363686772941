import React, { useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import HomeSectionsHeading from "./HomeSectionsHeading";
import HomePageBtn from "./HomePageBtn";
import { useNavigate } from "react-router-dom";

const swaperData = [
  {
    id: 1,
    hash: 'juice-filling-machine',
    navigate: 'VerticalPacking',
    title: `Multi lane sachet machine`,
    image: require('../../assets/images/slider/Carousel-Image-8.png')
  },
  {
    id: 2,
    hash: 'multi-head-weighing-machine',
    navigate: 'VerticalPacking',
    title: `Multi Head Weighing machine`,
    image: require('../../assets/images/slider/Carousel-Image-3.png')
  },
  {
    id: 3,
    hash: 'abcd',
    navigate: 'VerticalPacking',
    title: `Liquid & sachet filling machine`,
    image: require('../../assets/images/slider/Carousel-Image-2.png')
  },
  {
    id: 4,
    hash: 'automatic-feeding-machine',
    navigate: 'VerticalPacking',
    title: `granule sachet packing machine`,
    image: require('../../assets/images/slider/Carousel-Image-4.png')
  },
  {
    id: 5,
    hash: 'lbar-machine',
    navigate: 'OverWrapping',
    title: `L Bar Shrink Wrap Machine`,
    image: require('../../assets/images/slider/Carousel-Image-5.png')
  },
  {
    id: 6,
    hash: 'pyramid-tea-bag-machine',
    navigate: 'SpecialPacking',
    title: `Pyramid Tea bag packing machine`,
    image: require('../../assets/images/slider/Carousel-Image-6.png')
  },
  {
    id: 7,
    hash: 'pre-made-bag',
    navigate: 'VerticalPacking',
    title: `POuch And sealing machine`,
    image: require('../../assets/images/slider/Carousel-Image-1.png')
  },
  {
    id: 8,
    hash: 'automatic-tea-bag-machine',
    navigate: 'SpecialPacking',
    title: `Automated tea bag machine`,
    image: require('../../assets/images/slider/Carousel-Image-7.png')
  },
];

function Slider() {
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <div>
      <div className="container d-flex  justify-content-between gap-4 gap-lg-0 common-spacing">
        <div>
          <HomeSectionsHeading
            height={"64px"}
            title={"Top Choices: High-Demand Machines"}
            heading={"Most Selling Machines"}
          />
        </div>
        <div>
          <HomePageBtn btnText={'VIEW ALL MACHINES'} onClick={() => navigate('/VerticalPacking')} />
        </div>
      </div>
      <div className="container common-spacing-side pb-5 slider-container">
        <button className="custom-navigation prev" onClick={handlePrev}>
          <FaArrowLeft />
        </button>
        <Swiper
          ref={swiperRef}
          modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
          slidesPerView={4}
          loop
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}

          breakpoints={{
            320:{
              slidesPerView: 1,
              spaceBetween: 50,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
        >
          {swaperData.map((v, i) => {
            return (
              <SwiperSlide key={v.id}>
                <div className="carousel">
                  <div className="carousel-image-section">
                    <img className="carousel-image" src={v.image} alt={v.title} />
                  </div>
                  <div className="carousel-before" onClick={() => navigate(`/${v.navigate}#${v.hash}`)}>
                    <div className="d-flex justify-content-center align-items-center">
                      <h2 dangerouslySetInnerHTML={{ __html: v.title }} />
                      <FaArrowRightLong className="text-primary icon-left-arrow" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <button className="custom-navigation next" onClick={handleNext}>
          <FaArrowRight />
        </button>
      </div>
    </div>
  );
}

export default Slider;
