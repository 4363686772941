import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from '../pages/Home'
import Header from '../components/Header'
import ContactUs from '../pages/ContactUs'
import AboutUs from '../pages/AboutUs'
import Footer from '../components/Footer'
import PackingSolution from '../pages/PackingSolution'
import VerticalPacking from '../pages/VerticalPacking'
import VacuumPacking from '../pages/VacuumPacking'
import SpecialPacking from '../pages/SpecialPacking'
import PillowPacking from '../pages/PillowPacking'
import OverWrapping from '../pages/OverWrapping'
import LiquidAndPasteFilling from '../pages/LiquidAndPasteFilling'
import PowderProcessing from '../pages/PowderProcessing'

function MainRoute() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="AboutUs" element={<AboutUs />} />
        <Route path="ContactUs" element={<ContactUs />} />
        <Route path="PackingSolution" element={<PackingSolution />} />
        <Route path="VerticalPacking" element={<VerticalPacking />} />
        <Route path="VacuumPacking" element={<VacuumPacking />} />
        <Route path="SpecialPacking" element={<SpecialPacking />} />
        <Route path="PillowPacking" element={<PillowPacking />} />
        <Route path="OverWrapping" element={<OverWrapping />} />
        <Route path="LiquidAndPasteFilling" element={<LiquidAndPasteFilling />} />
        <Route path="PowderProcessing" element={<PowderProcessing />} />


      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default MainRoute